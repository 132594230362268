import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import {listApi as requestListApi, listApi} from "../../../../services/admin/request"
import {useHistory, useParams} from "react-router-dom"
import {getLocationApi,  updateApi, createApi} from "../../../../services/admin/location"
import useAuth from "../../../../hooks/useAuth"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import Autocomplete from "@material-ui/lab/Autocomplete"
import InputSelect from "../../../../components/Inputs/InputSelect";
import {apiList as apiListTariff} from "../../../../services/admin/tariff";
import {apiList as apiListCategory} from "../../../../services/admin/locationCategory";


export default function Fields() {
    const { id } = useParams()
    const { logout } = useAuth()
    const history = useHistory()

    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)
    const [loading, setLoading] = useState(true)

    const [listCompany, setListCompany] = useState([])
    const [listTariff, setListTariff] = useState([])
    const [listRegion, setListRegion] = useState([])
    const [listCountry, setListCountry] = useState([])
    const [listSubregion, setListSubregion] = useState([])
    const [listInvoiceEntity, setListInvoiceEntity] = useState([])
    const [locationCategories, setLocationCategories] = useState([])

    const [defaultCompany, setDefaultCompany] = useState(null)
    const [defaultCountry, setDefaultCountry] = useState(null)
    const [defaultRegion, setDefaultRegion] = useState(null)
    const [defaultSubregion, setDefaultSubregion] = useState(null)
    const [defaultInvoiceEntity, setDefaultInvoiceEntity] = useState(null)
    const [defaultLocationCategory, setDefaultLocationCategory] = useState(null)

    const [listRegionAll, setListRegionAll] = useState([])

    const [initialValues, setInitialValues] = useState(defaultValue())

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema()),
        enableReinitialize: true,
        onSubmit: async (formData) => {
            setMsgSuccess(false)
            setMsgDanger(null)

            if (id){
                const response = await updateApi(logout, formData)
                if (response.status) {
                    setMsgSuccess(true)
                } else {
                    setMsgDanger(response.errors)
                }
            } else {
                const response = await createApi(formData);
                if (response.status) {
                    history.push(`/admin/store/edit/${response.data.id}/1`);
                } else {
                    setMsgDanger(response.errors);
                }
            }

        },
    })

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "invoice-entities");
            if (response.status) {
                const temp = response.data.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc,
                    }
                })
                setListInvoiceEntity(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        apiGetLocation()
        apiListCompany()
        apiListCountry()
        apiListRegion()
        getTariff()
        setLoading(false)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getTariff()
        apiListCategories()
    }, [formik.values.idCompany])

    const getTariff = () => {
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos

        let params = {
            field: "name",
            sort: "asc",
        }

        if (formik.values.idCompany) { params.idCompany = formik.values.idCompany }

        apiListTariff(params).then(resp => {
            let temp = resp.data.data?.map((doc, key) => {
                return {
                    ...doc,
                    name: `${doc.name} - ${doc.description}`,
                }
            })
            temp.unshift({id: 0, name: "No Tariff"})
            setListTariff(temp)
        }).catch(err => {
            setListTariff([])
        })
    }

    const apiGetLocation = async () => {
        if (id) {
            const response = await getLocationApi(logout, {id: id});
            if (response.status) {
                setInitialValues({
                    id: response.data.id,
                    name: response.data.name,
                    code: response.data.code,
                    address1: response.data.address1,
                    address2: response.data.address2,
                    address3: response.data.address3,
                    address4: response.data.address4,
                    postalCode: response.data.postalCode,
                    idCompany: response.data.company?.id,
                    idCountry: response.data.country?.id,
                    idRegion: response.data.region?.id,
                    idSubregion: response.data.subregion?.id,
                    idLocationCategory: response.data.locationCategory?.id,
                    phone: response.data.phone,
                    isActive: response.data.isActive,
                    idInvoiceEntity: response.data.invoiceEntity,
                    emails: response.data.emails ?? "",
                    approverEmails: response.data.approverEmails ?? "",
                    warehouseEmails: response.data.warehouseEmails ?? "",
                    isVerified: response.data.isVerified,
                    minimumUnits: response.data.minimumUnits,
                    tariffId: response.data.tariffId ?? 0,
                })
                setDefaultInvoiceEntity(response.data.invoiceEntity)
                setDefaultCompany(response.data.company)
                setDefaultCountry(response.data.country)
                setDefaultRegion(response.data.region)
                setDefaultSubregion(response.data.subregion)
                setDefaultLocationCategory(response.data.locationCategory)

                apiListSubregion(response.data.region.id)
            }
        }
        setLoading(false)
    }

    const apiListCompany = async () => {
        const response = await requestListApi(logout, "company");
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListCompany(temp)
        }
    }

    const apiListCountry = async () => {
        const response = await requestListApi(logout, "country");
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListCountry(temp)
        }
    }

    const apiListRegion = async () => {
        const response = await requestListApi(logout, "region")
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListRegionAll(temp)
        }
    }

    const apiListSubregion = async (region) => {
        const response = await requestListApi(logout, `region/subregion/region/${region}`);
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListSubregion(temp)
        }
    }

    const apiListCategories = () => {
        setLocationCategories([])
        setDefaultLocationCategory(null)

        if (formik.values.idCompany) {
            let params = {}
            params.idCompany = formik.values.idCompany

            apiListCategory(params).then(resp => {
                let temp = resp.data.data?.map((doc, key) => {
                    return {
                        id: doc.id,
                        nameWithCompany: doc.name,
                        ...doc,
                    }
                })

                setLocationCategories(temp)
            }).catch(err => {
                setLocationCategories([])
            })
        }
    }

    // Escuchamos cuando una de las dos variables es modificada, para recargar los valores de Región
    useEffect(() => {
        showRegionInSelect(initialValues.idCompany)
    }, [initialValues, listRegionAll]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        showSubregionInSelect(initialValues.idRegion)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Filtramos las regiones segun la companya
    const showRegionInSelect = (idCompany) => {
        setListRegion(
            listRegionAll.filter(item => {
                let add = false
                if (idCompany === item.idCompany.id) {
                    add = true
                }
                return add
            })
        )
    }

    // Filtramos las subregiones
    const showSubregionInSelect = (idRegion) => {
        apiListSubregion(idRegion)
    }

    const onChangeCompany = (event, value) => {
        if (value !== null) {
            formik.values.idCompany = value.id
            setDefaultCompany({
                id: value.id,
                name: value.name,
            })

            showRegionInSelect(value.id)
        } else {
            setDefaultCompany(null)
            formik.values.idCompany = null
            showRegionInSelect(null)
            showSubregionInSelect(null)
        }

        // Limpiamos los valores de la tienda (Location)
        setDefaultRegion(null)
        formik.values.idRegion = null

        // Limpiamos los valores de la subregion
        setDefaultSubregion(null)
        formik.values.idSubregion = null
    }

    const onChangeCountry = (event, value) => {
        if (value !== null) {
            formik.values.idCountry = value.id
            setDefaultCountry({
                id: value.id,
                name: value.name,
            })

        } else {
            setDefaultCountry(null)
            formik.values.idCountry = null
        }
    }

    const onChangeRegion = (event, value) => {
        if (value !== null) {
            formik.values.idRegion = value.id
            setDefaultRegion({
                id: value.id,
                name: value.name,
            })

            showSubregionInSelect(value.id)
        } else {
            setDefaultRegion(null)
            formik.values.idRegion = null
            showSubregionInSelect(null)
        }

        // Limpiamos los valores de la subregion
        setDefaultSubregion(null)
        formik.values.idSubregion = null
    }

    if (loading) return ""

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {msgSuccess && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-success">
                            Location data has been saved correctly
                        </Box>
                    </Grid>
                )}

                {msgDanger && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-danger">
                            {msgDanger.map((item) => {
                                return (
                                    <span>
                                        {" "}
                                        {item} <br />{" "}
                                    </span>
                                );
                            })}
                        </Box>
                    </Grid>
                )}

                <Box border={1} className="p-20 box-default">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} className="border-bottom">
                            <h5 className="p-0 mt-0">Info</h5>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>

                            {id && (
                                <div className="my-2">
                                    <TextField
                                        variant="outlined"
                                        label="* ID Store"
                                        className="form-control"
                                        name="id"
                                        type="text"
                                        disabled={loading}
                                        value={formik.values.id}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            )}
                            <div className="my-2 mb-2">
                                <Autocomplete
                                    options={listCompany}
                                    value={defaultCompany}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    disabled={loading}
                                    onChange={onChangeCompany}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Company "
                                            variant="outlined"
                                            name="idCompany"
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="* NetSuite ID"
                                    className="form-control"
                                    name="code"
                                    type="text"
                                    disabled={loading}
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                />
                                {formik.errors.code && (
                                    <p className="text-100 text-danger">
                                        {formik.errors.code}
                                    </p>
                                )}
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="* Location Name"
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    disabled={loading}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                />
                                {formik.errors.name && (
                                    <p className="text-100 text-danger">
                                        {formik.errors.name}
                                    </p>
                                )}
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="Telephone"
                                    className="form-control"
                                    name="phone"
                                    type="text"
                                    disabled={loading}
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </div>

                            <div className="my-2">
                                <Autocomplete
                                    options={locationCategories}
                                    value={defaultLocationCategory}
                                    disabled={loading}
                                    getOptionLabel={(option) =>
                                        option.nameWithCompany ? option.nameWithCompany : option.name
                                    }
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            formik.values.idLocationCategory =
                                                newValue.id;
                                            setDefaultLocationCategory(newValue);
                                        } else {
                                            setDefaultLocationCategory(null);
                                            formik.values.idLocationCategory =
                                                null;
                                        }
                                    }}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Store Category"
                                            name="idLocationCategory"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="Approver emails"
                                    name="approverEmails"
                                    disabled={loading}
                                    onChange={formik.handleChange}
                                    value={formik.values?.approverEmails}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                                <small>* If you want to add more than one email the values must be separated by ";"</small>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>

                            <div className="my-2 mb-2">
                                <Autocomplete
                                    options={listInvoiceEntity}
                                    value={defaultInvoiceEntity}
                                    disabled={loading}
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            formik.values.idInvoiceEntity =
                                                newValue.id;
                                            setDefaultInvoiceEntity(newValue);
                                        } else {
                                            setDefaultInvoiceEntity(null);
                                            formik.values.idInvoiceEntity =
                                                null;
                                        }
                                    }}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Invoice entity"
                                            name="idInvoiceEntity"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="Minimum units"
                                    className="form-control"
                                    name="minimumUnits"
                                    type="number"
                                    disabled={loading}
                                    value={formik.values?.minimumUnits}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="* Email"
                                    name="emails"
                                    disabled={loading}
                                    onChange={formik.handleChange}
                                    value={formik.values?.emails}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                                {formik.errors.emails && (
                                    <p className="text-100 text-danger">
                                        {formik.errors.emails}
                                    </p>
                                )}
                                <small>* If you want to add more than one email the values must be separated by ";"</small>
                            </div>

                            <div className="my-2">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={loading}
                                            checked={formik.values.isActive}
                                            onChange={formik.handleChange}
                                            name="isActive"
                                            color="primary"
                                        />
                                    }
                                    label="Visible"
                                />
                            </div>

                            <div className="my-2">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={loading}
                                            checked={formik.values.isVerified}
                                            onChange={formik.handleChange}
                                            name="isVerified"
                                            color="primary"
                                        />
                                    }
                                    label="Needs Approval to order"
                                />
                            </div>

                            <div className="my-2">
                                <InputSelect
                                    label="Tariff"
                                    name="tariffId"
                                    onChange={formik.handleChange}
                                    value={formik.values.tariffId}
                                    options={listTariff}
                                />
                            </div>

                            <div className="my-3">
                                <TextField
                                    variant="outlined"
                                    label="Warehouse emails"
                                    name="warehouseEmails"
                                    disabled={loading}
                                    onChange={formik.handleChange}
                                    value={formik.values?.warehouseEmails}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                                <small>* If you want to add more than one email the values must be separated by ";"</small>
                            </div>

                        </Grid>

                    </Grid>
                </Box>

                <Box border={1} className="p-20 box-default mt-2 ">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} className="border-bottom">
                            <h5 className="p-0 mt-0">Address</h5>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className="my-2 mb-2">
                                <Autocomplete
                                    options={listRegion}
                                    value={defaultRegion}
                                    disabled={loading}
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={onChangeRegion}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Region"
                                            name="idRegion"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2 mb-2">
                                <Autocomplete
                                    options={listSubregion}
                                    value={defaultSubregion}
                                    disabled={loading}
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            formik.values.idSubregion = newValue.id
                                            setDefaultSubregion({
                                                id: newValue.id,
                                                name: newValue.name,
                                            });
                                        } else {
                                            setDefaultSubregion(null)
                                            formik.values.idLocation = null
                                        }
                                    }}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Subregion"
                                            name="idSubregion"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="PostCode"
                                    className="form-control"
                                    name="postalCode"
                                    type="text"
                                    disabled={loading}
                                    value={formik.values.postalCode}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="Address 1"
                                    className="form-control"
                                    name="address1"
                                    type="text"
                                    disabled={loading}
                                    value={formik.values.address1}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="Address 2"
                                    className="form-control"
                                    name="address2"
                                    type="text"
                                    disabled={loading}
                                    value={formik.values.address2}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="Address 3"
                                    className="form-control"
                                    name="address3"
                                    type="text"
                                    disabled={loading}
                                    value={formik.values.address3}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="Address 4"
                                    className="form-control"
                                    name="address4"
                                    type="text"
                                    disabled={loading}
                                    value={formik.values.address4}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </div>


                            <div className="my-2 mb-2">
                                <Autocomplete
                                    options={listCountry}
                                    value={defaultCountry}
                                    disabled={loading}
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={onChangeCountry}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Country"
                                            name="idCountry"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={3} className="p-20">
                    <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            type="button"
                            color="secondary"
                            disabled={loading}
                            href="/admin/store/management"
                            fullWidth
                        >
                            { id ? ( <><ArrowBackIos /> Back to list </>) : "Cancel" }
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                            fullWidth
                        >
                            { id ? "Modify" : "Create" }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

function defaultValue() {
    return {
        id: "",
        name: "",
        code: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        postalCode: "",
        phone: "",
        idCompany: null,
        idCountry: null,
        idRegion: null,
        isActive: true,
        isVerified: false,
        idInvoiceEntity: null,
        idLocationCategory: null,
        idSubregion: null,
        email: "",
        minimumUnits: 0,
        approverEmails: "",
        tariffId: null,
    };
}

function validationSchema() {
    return {
        code: Yup.string().required("The code is required"),
        name: Yup.string().required("The name is required"),
        emails: Yup.string().required("The email is required"),
    }
}
