import React, {useEffect, useMemo, useState} from "react";
import {listApi} from "../../../../services/admin/request";
import {exportLocationsApi, importLocationsApi} from "../../../../services/admin/location"
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import MsgSuccess from "../../../../components/Admin/MsgSuccess";
import MsgError from "../../../../components/Admin/MsgError";
import Loading from "../../../../components/User/Loading";

export default function Management() {
    const {logout} = useAuth()

    const [location, setLocation] = useState(null)
    const [ids, setIds] = useState([])
    const [file, setFile] = useState(null)
    const [msgError, setMsgError] = useState(null)
    const [msgSuccess, setMsgSuccess] = useState(null)
    const [loading, setLoading] = useState(true)

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "company",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "STORE",
                accessor: "name",
            },
            {
                Header: "REGION",
                accessor: "region"
            },
            {
                Header: "SUBREGION",
                accessor: "subregion"
            },
            {
                Header: "COUNTRY",
                accessor: "country"
            },
            {
                Header: "VISIBLE",
                accessor: "access",
                Filter: SelectColumnFilter,
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    )

    const handleChangeFile = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files)
        } else {
            setFile(null)
        }
    }

    const onChangeLocations = (data) => {
        let temporalIds = []
        setIds([]);

        for (let i = 0; i < data.length; i++) {
            temporalIds.push(data[i].original.id)
        }

        setIds(temporalIds)
    }

    const exportLocations = async () => {
        const response = await exportLocationsApi(logout, {"locations": ids});

        if (response.status) {
            window.open(response.data, '_blank');
        }
    }

    const importLocations = async () => {
        setMsgSuccess([])
        setMsgError([])

        let formData = new FormData()

        formData.append('excelFile', file[0])

        const response = await importLocationsApi(logout, formData)

        if (response.status) {
            setMsgSuccess([response.data])
            getLocation()
        } else {
            setMsgError(response.errors)
        }

    }

    useEffect(() => {
        getLocation()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getLocation = async () => {
        setLoading(true)
        const response = await listApi(logout, 'location');
        if (response.status) {
            const temp = response.data.map((doc) => {
                let href = `/admin/store/edit/${doc.id}/1`

                let access = "No";
                if (doc.isActive) {
                    access = "Yes";
                }

                return {
                    ...doc,
                    id: doc.id,
                    company: doc.idCompany?.name,
                    country: doc.idCountry?.name,
                    region: doc.idRegion?.name,
                    subregion: doc.idSubregion?.name,
                    access: access,
                    actions: (
                        <Link color="primary" to={href}>
                            Edit
                        </Link>
                    ),
                }
            })

            setLocation(temp)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const downloadTemplate = () => {
        window.open("/import_locations.xlsx", '_blank');
    }

    return (
        <>
            <Grid container spacing={3} justifyContent={"space-between"}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Maintain Store</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} md={2} lg={2} style={{textAlign: "right"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={exportLocations}
                                style={{margin: "20px 0px"}}
                            >
                                Export
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} style={{textAlign: "right"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{margin: "20px 0px"}}
                                href="/admin/store/create"
                            >
                                + add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3}>
                            <div style={{marginRight: "20px"}}>
                                <TextField
                                    variant="outlined"
                                    label="Import stores"
                                    name="excelFile"
                                    size="small"
                                    type="file"
                                    onChange={handleChangeFile}
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{marginRight: "20px"}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={file === null}
                                    onClick={importLocations}
                                    fullWidth
                                >
                                    Import
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={downloadTemplate}
                                fullWidth
                            >
                                Download template
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MsgSuccess msg={msgSuccess}/>
                    <MsgError msg={msgError}/>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Loading show={loading} title=""/>
                    { (location && !loading) && (
                        <Box border={1} className="box-default">
                            <TableFilterBase
                                columns={columns}
                                data={location}
                                selectedRows={location}
                                onSelectedRowsChange={setLocation}
                                onChangeContent={onChangeLocations}
                                search={false}
                                selectable={false}
                            />
                    </Box>
                    )}

                </Grid>
            </Grid>
        </>
    );
}


