import { BASE_PATH, PATH_BASE_EXTENSION } from "../../utils/constants";
import { authFetch } from "../../utils/fetch";

export async function showProductsApi(logout) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/product/list`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export async function updateProductApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/product/update`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getProductApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/product/${data.id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function createApi(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/product/create`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export async function saveStockDataReq(data, href) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/${href}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}


/**
 * Relación entre el producto y la tienda (location).
 * Para añadir o desvincular una tienda del producto.
 * @param formData
 * @returns {Promise<null|any>}
 */
export async function createDeleteRelationProduct(data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/product/select-location`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}