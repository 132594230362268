import React, {useEffect} from "react";
import moment from "moment";

export default function App(props) {

    const {value, label, onChange, name} = props;

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        if (selectedDate) {
            const unixTimestamp = moment(selectedDate, "YYYY-MM-DD").format("X");
            onChange({target: {name: name, value: unixTimestamp}})
        } else {
            onChange({target: {name: name, value: ''}})
        }
    }

    return (
        <>
            <div className="form__div">
                <input
                    type="date"
                    className="form__input"
                    name={name}
                    onChange={handleDateChange}
                    value={value ? moment.unix(value).format("YYYY-MM-DD") : null}
                />
                <label htmlFor="" className="form__label">{label}</label>
            </div>

            <style>
                {`
                    /*===== VARIABLES CSS =====*/
                    :root{
                        /*===== Colores =====*/
                        --first-color: #ef6f28;
                        --input-color: #80868B;
                        --border-color: #DADCE0;
                    
                        /*===== Fuente y tipografia =====*/
                        --body-font: 'Roboto', sans-serif;
                        --normal-font-size: 1rem;
                        --small-font-size: .75rem;
                    }
                    
                    input[type="date"]::-webkit-calendar-picker-indicator {
                        background: transparent;
                        bottom: 0;
                        color: transparent;
                        cursor: pointer;
                        height: auto;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: auto;
                    }
                    
                    .form__title{
                        font-weight: 400;
                        margin-bottom: 3rem;
                    }
                    .form__div{
                        position: relative;
                        height: 48px;
                    }
                    .form__input{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 37px;
                        font-size: var(--normal-font-size);
                        border: 1px solid var(--border-color);
                        border-radius: .3rem;
                        outline: none;
                        background: none;
                        padding: 0 .8rem;
                        z-index: 1;
                    }
                    .form__label{
                        position: absolute;
                        left: 1rem;
                        top: 1rem;
                        padding: 0 .25rem;
                        background-color: #fff;
                        color: var(--input-color);
                        transition: .3s;
                    }
                    .form__button{
                        display: block;
                        margin-left: auto;
                        padding: .75rem 2rem;
                        outline: none;
                        border: none;
                        background-color: var(--first-color);
                        color: #fff;
                        font-size: var(--normal-font-size);
                        border-radius: .5rem;
                        cursor: pointer;
                        transition: .3s;
                    }
                    
                    .form__input + .form__label{
                        top: -.5rem;
                        left: .8rem;
                        font-size: var(--small-font-size);
                        font-weight: 500;
                        z-index: 10;
                    }
                    
                    .form__input:hover{
                        border: 1.5px solid #ef6f28;
                    }
                    
                    .form__input:not(:placeholder-shown).form__input:not(:focus)+ .form__label{
                        top: -.5rem;
                        left: .8rem;
                        font-size: var(--small-font-size);
                        font-weight: 500;
                        z-index: 10;
                    }
                    
                    /*Input focus move up label*/
                    .form__input:focus + .form__label{
                        top: -.5rem;
                        left: .8rem;
                        color: var(--first-color);
                        font-size: var(--small-font-size);
                        font-weight: 500;
                        z-index: 10;
                    }
                    
                    /*Input focus sticky top label*/
                    .form__input:not(:placeholder-shown).form__input:not(:focus)+ .form__label{
                        top: -.5rem;
                        left: .8rem;
                        font-size: var(--small-font-size);
                        font-weight: 500;
                        z-index: 10;
                    }
                    
                    /*Input focus*/
                    .form__input:focus{
                        border: 2px solid var(--first-color);
                    }
                `}
            </style>
        </>
    );
}
