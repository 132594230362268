import {BASE_PATH, PATH_BASE_EXTENSION_ADMIN} from "../../utils/constants";
import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}`;

export function apiLogMail(props) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    let query = "";
    if (props.mailTo) { query += `&mailTo=${props.mailTo}`; }

    return axios.get(`${baseUrl}/log-mail/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}
