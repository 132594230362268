import React, {useEffect, useMemo, useState} from "react"
import {getAllTransactionKPILog} from "../../../../services/admin/transaction";

import moment from "moment";
import NewTable from "../../../../components/Admin/NewTable"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import {DASH_MAX_ROW} from "../../../../utils/constants";
import useAuth from "../../../../hooks/useAuth"
import InputText from "../../../../components/Admin/Inputs/InputText";
import Button from "@material-ui/core/Button";
import InputSelect from "../../../../components/Admin/Inputs/InputSelect";
import DatePickerSimple from "../../../../components/Admin/Inputs/DatePickerSimple";
import DatePickerTime from "../../../../components/Admin/Inputs/DatePickerTime";
import {listApi} from "../../../../services/admin/request";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import { useParams } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

export default function Management() {
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingExcel, setLoadingExcel] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [msgError, setMsgError] = useState([])
    const [iniStart, setIniStart] = useState(true)
    const {logout} = useAuth()

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData(false)
        }, 1500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const exportData = async () => {
        console.clear()
        console.log("export")
        return false
        setLoadingExcel(true)
        getItemsData(true)
    }

    const getItemsData = (exportRow = false) => {
        setMsgError([])
        let field = state.sort.field
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            transactionId: "",
            user: "",
            exportRow: exportRow
        }

        if (state.transactionId) {
            params.transactionId = state.transactionId
        }
        if (state.dateCreatedFrom) {
            params.dateCreatedFrom = state.dateCreatedFrom
        }
        if (state.dateCreatedTo) {
            params.dateCreatedTo = state.dateCreatedTo
        }
        
        if (state.company) {
            params.company = state.company
        }
        if (state.location) {
            params.location = state.location
        }

        if (state.country) {
            params.country = state.country
        }
        if (state.region) {
            params.region = state.region
        }
        if (state.zone) {
            params.zone = state.zone
        }

        /* if (params.dateFromDate && params.dateFromTime) {
            // Unimos fecha y hora
            params.dateFromDate = moment(`${moment.unix(params.dateFromDate).format("YYYY-MM-DD")} ${params.dateFromTime}`).format("X")
            // Eliminamos params.dateFromTime
            delete params.dateFromTime
        } */


    

        getAllTransactionKPILog(logout, params).then(resp => {
            if (exportRow) {
                window.open(resp.data, '_blank');
                setLoadingExcel(false)
            } else {
                setRowCount(0)
                setRows([])

                let temp = resp.data?.map(doc => {
                    return {
                        ...doc,
                        id: doc.id,
                        idTransaction: doc.idTransaction,
                        idUser: doc.userName + " " + doc.userSurname,
                        idTransactionStatus: doc.transactionStatusName,
                        changeDate: moment.unix(doc.changeDate).format("D/M/Y h:mm a"),
                        transaction_date_awaiting_approval: doc.transaction_date_awaiting_approval ?? "N/A",
                        transaction_date_approved: doc.transaction_date_approved ?? "N/A",
                        transaction_date_on_hold: doc.transaction_date_on_hold ?? "N/A",
                        transaction_date_processing: doc.transaction_date_processing ?? "N/A",
                        transaction_date_shipped: doc.transaction_date_shipped ?? "N/A",
                        transaction_date_cancelled: doc.transaction_date_cancelled ?? "N/A",
                        transaction_date_declined: doc.transaction_date_declined ?? "N/A",

                    }
                })
                setRowCount(resp.count)
                setRows(temp)
                setLoading(false)
            }
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingExcel(false)
            setRowCount(0)
            setRows([])
        })
    }

    function tableColumns() {
        return [
            {field: "companyName", headerName: "Company", width: 150, flex: 1, sortable: true},
            {field: "countryName", headerName: "Country", width: 150, flex: 1},
            {field: "regionName", headerName: "Zone", width: 150, flex: 1},
            {field: "subregionName", headerName: "Region", width: 150, flex: 1},
            {field: "storeName", headerName: "Store", width: 150, flex: 1},
            {field: "idTransaction", headerName: "ORDER", width: 150, flex: 1},
            {field: "quantity", headerName: "Order Qty", width: 150, flex: 1},
            {field: "transaction_date_created", headerName: "Created", width: 150, flex: 1},
            {field: "transaction_date_awaiting_approval", headerName: "Awaiting Approval", width: 150, flex: 1},
            {field: "transaction_date_approved", headerName: "Approved", width: 150, flex: 1},
            {field: "transaction_date_on_hold", headerName: "On Hold", width: 150, flex: 1},
            {field: "transaction_date_processing", headerName: "Processing", width: 150, flex: 1},
            {field: "transaction_date_shipped", headerName: "Shipped", width: 150, flex: 1},
            {field: "transaction_date_cancelled", headerName: "Cancelled", width: 150, flex: 1},
            {field: "transaction_date_declined", headerName: "Denied", width: 150, flex: 1},
        ]
    }

    return (<>

            

            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">

                        <LoadingSpinner loading={iniStart}/>

                        <Filters
                            state={state}
                            setState={setState}
                            iniStart={iniStart}
                            setIniStart={setIniStart}
                        />

                        {!iniStart && (
                            <NewTable
                                columns={tableColumns()}
                                rows={rows}
                                rowCount={rowCount}
                                filter={state}
                                setFilter={setState}
                                loading={loading}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
            
        </>
    )
}

function Filters(props) {

    const {state, setState, setIniStart, iniStart, exportData, getItemsData} = props
    const [loadingExcel, setLoadingExcel] = useState(false)
    const {logout} = useAuth()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const [company, setCompany] = useState([])
    const [zone, setZone] = useState([])
    const [region, setRegion] = useState([])
    const [country, setCountry] = useState([])
    const [location, setLocation] = useState([])

    useEffect(() => {
        getCompany()
        getRegion()
        getCountry()
        getZone()
        getLocation()
    }, [])

    useEffect(() => {
        if (company.length > 0 && zone.length > 0 && country.length > 0 && region.length > 0) {
            setIniStart(false)
        }
    }, [company, zone, country, region])

    useEffect(() => {
        getZone()
        getLocation()

        setState({...state, region: "", zone: ""})

    }, [state.company])

    useEffect(() => {
        getRegion()

        setState({...state, region: ""})

    }, [state.zone])

    const getLocation = async () => {
        const response = await listApi(logout, 'location?idCompany=' + state.company)
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setLocation(temp)
        }
    }

    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCompany(temp)
        }
    }

    const getZone = async () => {
        const response = await listApi(logout, 'region?idCompany=' + state.company)
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setZone(temp)
        }
    }

    const getRegion = async () => {
        const response = await listApi(logout, "region/subregion/list?idRegion=" + state.zone)
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setRegion(temp)
        }
    }

    const getCountry = async () => {
        const response = await listApi(logout, 'country')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCountry(temp)
        }
    }

    const exportDataKPI = async () => {
        
        setLoadingExcel(true)
        getItemsDataKPI(true)
    }

    const getItemsDataKPI = (exportRow = false) => {
        let field = state.sort.field
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            transactionId: "",
            user: "",
            exportRow: exportRow
        }

        if (state.transactionId) {
            params.transactionId = state.transactionId
        }
        if (state.dateCreatedFrom) {
            params.dateCreatedFrom = state.dateCreatedFrom
        }
        if (state.dateCreatedTo) {
            params.dateCreatedTo = state.dateCreatedTo
        }
        
        if (state.company) {
            params.company = state.company
        }
        if (state.location) {
            params.location = state.location
        }
        if (state.country) {
            params.country = state.country
        }
        if (state.region) {
            params.region = state.region
        }
        if (state.zone) {
            params.zone = state.zone
        }

        /* if (params.dateFromDate && params.dateFromTime) {
            // Unimos fecha y hora
            params.dateFromDate = moment(`${moment.unix(params.dateFromDate).format("YYYY-MM-DD")} ${params.dateFromTime}`).format("X")
            // Eliminamos params.dateFromTime
            delete params.dateFromTime
        } */


    

        getAllTransactionKPILog(logout, params).then(resp => {
            if (exportRow) {
                window.open(resp.data, '_blank');
                setLoadingExcel(false)
            } else {
                
            }
        }).catch(err => {
        })
    }

    return (
        <>
            {!iniStart && (
                <Grid container spacing={1} style={{padding: 10}}>

                    <Grid item md={3}>
                        <InputSelect
                            label="Company"
                            name="company"
                            onChange={handleChange}
                            value={state?.company}
                            options={company}
                        />
                    </Grid>

                    <Grid item md={3}>
                        <InputSelect
                            label="Store"
                            name="location"
                            onChange={handleChange}
                            value={state?.location}
                            options={location}
                            disabled={state.company ? false : true}
                        />
                    </Grid>

                    <Grid item md={3}>
                        <InputText
                            label="Order ID"
                            name="transactionId"
                            onChange={handleChange}
                            value={state.transactionId}
                        />
                    </Grid>

                    <Grid item md={3}>
                        <InputButtonLoading
                            onClick={exportDataKPI}
                            loading={loadingExcel}
                            label="Export (.xlsx)"
                            style={{width: 200}}
                        />
                    </Grid>

                    <Grid item md={6}>
                        <DatePickerSimple
                            name="dateCreatedFrom"
                            label="Date from (Creation)"
                            value={state.dateCreatedFrom}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item md={6}>
                        <DatePickerSimple
                            name="dateCreatedTo"
                            label="Date to (Creation)"
                            value={state.dateCreatedTo}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputSelect
                            label="Zone"
                            name="zone"
                            onChange={handleChange}
                            value={state?.zone}
                            options={zone}
                            disabled={!state?.company}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputSelect
                            label="Region"
                            name="region"
                            onChange={handleChange}
                            value={state?.region}
                            options={region}
                            disabled={!state?.zone}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputSelect
                            label="Country"
                            name="country"
                            onChange={handleChange}
                            value={state?.country}
                            options={country}
                        />
                    </Grid>

                </Grid>
            )}
        </>
    )
}

function initialValues() {
    return {
        offset: 0, sort: {field: "changeDate", sort: "desc"},
        transactionId: "",
        dateCreatedFrom: "",
        dateCreatedTo: "",
        country: "",
        region: "",
        zone: "",
        company: "",
        location: ""
    }
}
