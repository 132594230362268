import Basic from "../../../containers/Admin/Layouts/Basic";
import ContainersEdit from "../../../containers/Admin/Location/Fields";
import ContainerManagementProduct from "../../../containers/Admin/Location/Product";
import { useParams } from "react-router-dom";

import React, {useEffect, useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {getLocationApi} from "../../../services/admin/location";
import useAuth from "../../../hooks/useAuth";

export default function Edit() {
    const { id, tab } = useParams()
    const { logout } = useAuth()
    const [name, setName] = useState(null)
    const [value, setValue] = React.useState(tab)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        (async () => {
            if (id){
                const response = await getLocationApi(logout, {id: id})
                if (response.status) {
                    setName(response.data.name)
                }
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Basic menu="location">
            <Grid container spacing={3} className="pl-20 pr-20">
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        type="button"
                        color="primary"
                        href="/admin/store/management"
                        style={{
                            float: "left",
                        }}
                    >
                        <ArrowBackIos />
                        BACK TO LIST
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <h2 className="p-0">Store Settings
                        {id && (
                            <span className="font-w-100"> {name}</span>
                        )}
                    </h2>
                </Grid>
            </Grid>
            <TabContext value={value}>
                <AppBar
                    position="static"
                    style={{
                        background: "#fff",
                        color: "#000",
                        boxShadow: "none",
                    }}
                >
                    <TabList onChange={handleChange}>
                        <Tab label="Settings" value="1" />
                        <Tab label="Products" value="2" />
                    </TabList>
                </AppBar>

                <TabPanel value="1">
                    <ContainersEdit menu="location" />
                </TabPanel>
                <TabPanel value="2">
                    <ContainerManagementProduct />
                </TabPanel>
            </TabContext>
        </Basic>
    );
}
