import React, {useEffect, useMemo, useState} from "react";
import {listApi} from "../../../../../services/admin/request";
import useAuth from "../../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";
import TableBase from "../../../../../components/Admin/TableFilterBaser";
import Link from "@material-ui/core/Link";
import {saveStockDataReq} from "../../../../../services/admin/product";

export default function Management() {
    const {id} = useParams();
    const {logout} = useAuth();

    const [warehouses, setWarehouses] = useState(null);
    const [selectedRows, setSelectedRows] = useState({});
    const [msgSuccess, setMsgSuccess] = useState(false);
    const [msgDanger, setMsgDanger] = useState(null);

    const columns = useMemo(
        () => [
            {
                Header: "CODE",
                accessor: "code",
                disableFilters: true
            },
            {
                Header: "NAME",
                accessor: "name",
                disableFilters: true
            },
            {
                Header: "REGION",
                accessor: "regionName",
                disableFilters: true
            },
            {
                Header: "STOCK ON HAND",
                accessor: "stockOnHand",
                disableFilters: true
            },
            {
                Header: "STOCK ON ORDER",
                accessor: "stockOnOrder",
                disableFilters: true
            },
            {
                Header: "STOCK IN TRANSIT",
                accessor: "stockInTransit",
                disableFilters: true
            },
            {
                Header: "COMMENT",
                accessor: "comment",
                disableFilters: true
            },
            {
                Header: "BALANCE IDP PO",
                accessor: "stockBalanceIdpPo",
                disableFilters: true
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    );

    useEffect(() => {
        (async () => {
            const response = await listApi(
                logout,
                `product/warehouse-stock/${id}`
            );
            if (response.status) {
                const temp = response.data.map((doc) => {
                    let href = `/admin/warehouse/edit/${doc.idWarehouse}/2`;

                    return {
                        ...doc,
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                    };
                });

                setWarehouses(temp);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const saveStockData = async (event) => {
        event.preventDefault();

        const href = `product/set-warehouse-stock`;

        const data = new FormData(event.target);

        data.append("idProduct", id);

        const object = {};

        data.forEach((value, key) => {
            object[key] = value;
        });

        const response = await saveStockDataReq(object, href);

        if (response.status) {
            setMsgSuccess(true);
        } else {
            setMsgDanger(response.errors)
        }
    };

    if (!warehouses) return "";

    return (
        <>
            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">
                        Stock data has been successfully saved
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>
                                    {item} <br/>{" "}
                                </span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <Box border={1} className="box-default">
                            <form onSubmit={saveStockData}>
                                <TableBase
                                    columns={columns}
                                    data={warehouses}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    isButtonVisible={true}
                                    downInfo={false}
                                    upInfo={true}
                                />
                            </form>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
