import React, {useEffect, useState} from "react";
import {apiList} from "../../../../services/admin/transaction";
import moment from "moment";
import {Box, Grid, IconButton} from "@mui/material";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import NewTable from "../../../../components/Admin/NewTable";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DatePickerSimple from "../../../../components/Admin/Inputs/DatePickerSimple";
import InputSelect from "../../../../components/Admin/Inputs/InputSelect";
import {listApi} from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import InputText from "../../../../components/Admin/Inputs/InputText";
import { exportOrders } from "../../../../services/admin/transaction"
import Button from "@material-ui/core/Button";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";

export default function Management() {
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [iniStart, setIniStart] = useState(true)
    const [ids, setIds] = useState([]);
    const {logout} = useAuth()
    const [loadingExport, setLoadingExport] = useState(false)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData(false)
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = (exportExcel = false) => {
        if (exportExcel) {
            setLoadingExport(true)
        }

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
        }

        if (state.dateFrom) {
            params.dateFrom = state.dateFrom
        }
        if (state.dateTo) {
            params.dateTo = state.dateTo
        }
        if (state.status) {
            params.status = state.status
        }
        if (state.company) {
            params.company = state.company
        }
        if (state.zone) {
            params.region = state.zone
        }
        if (state.region) {
            params.subregion = state.region
        }
        if (state.location) {
            params.location = state.location
        }
        if (state.id) {
            params.id = state.id
        }
        if (exportExcel) {
            params.exportExcel = exportExcel
        }

        apiList(params).then(resp => {
            if (exportExcel) {
                window.open(resp.data.data, '_blank')
                setLoadingExport(false)
            } else {
                setRowCount(resp.data?.total)

                let temp = resp.data.data?.map(doc => {
                    return {
                        ...doc,
                        id: doc.transaction_id,
                        orderNo: doc.transaction_id,
                        userName: `${doc.user_name} ${doc.user_surnames}`,
                        orderDate: moment.unix(doc.transaction_creationDate).utc().format('DD-MMM-YYYY HH:mm A'),
                    }
                })

                setRows(temp)
                setLoading(false)
            }
        }).catch(err => {
            if (exportExcel) {
                setLoadingExport(false)
            } else {
                setLoadingExport(false)
                setLoading(false)
                setRows([])
                setRowCount(0)
            }
        })
    }

    return (
        <>
            <HeaderPage title="Display Orders">
                <InputButtonLoading
                    style={{float: 'right'}}
                    onClick={() => getItemsData(true)}
                    loading={loadingExport}
                    label="Export (.xlsx)"
                />
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <Filters
                            state={state}
                            setState={setState}
                            iniStart={iniStart}
                            setIniStart={setIniStart}
                        />

                        <NewTable
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
        id: "",
        dateFrom: "",
        dateTo: "",
        status: "",
        company: "",
        zone: "",
        region: "",
        location: "",
    }
}

function tableColumns() {
    return [
        {
            headerName: "ORDER DATE",
            field: "orderDate",
            sortable: false,
            flex: 1
        },
        {
            headerName: "COMPANY",
            field: "company_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "ZONE",
            field: "region_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "REGION",
            field: "subregion_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "STORE",
            field: "location_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "ORDER NO",
            field: "orderNo",
            sortable: false,
            flex: 1
        },
        {
            headerName: "STATUS",
            field: "status_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "TOTAL",
            field: "price",
            sortable: false,
            flex: 1
        },
        {
            headerName: "ACTIONS",
            field: "actions",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admin/transaction/edit/${params.transaction_id}/1`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit"/>
                            </IconButton>
                        </Link>
                    </>
                )
            },
        },
    ]
}

function Filters(props) {

    const {state, setState, setIniStart, iniStart} = props

    const {logout} = useAuth()

    const [company, setCompany] = useState([])
    const [zone, setZone] = useState([])
    const [region, setRegion] = useState([])
    const [country, setCountry] = useState([])
    const [location, setLocation] = useState([])
    const optionsStatus = [
        {id: "1", name: "Created"},
        {id: "2", name: "Awaiting approval"},
        {id: "3", name: "Approved"},
        {id: "8", name: "On hold"},
        {id: "4", name: "Processing"},
        {id: "5", name: "Shipped"},
        {id: "6", name: "Canceled"},
        {id: "7", name: "Declined"},
    ]

    const [loadingCompany, setLoadingCompany] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(false)
    const [loadingZone, setLoadingZone] = useState(false)
    const [loadingRegion, setLoadingRegion] = useState(false)

    useEffect(() => {
        getCompany()
    }, [])

    useEffect(() => {
        if (company.length > 0) {
            setIniStart(false)
        }
    }, [company, zone, country, region])

    useEffect(() => {
        getLocation()
    },[state.region, state.zone, state.company])

    useEffect(() => {
        getZone()
        setState({...state, region: "", zone: ""})

    }, [state.company])

    useEffect(() => {
        getRegion()

        setState({...state, region: ""})

    }, [state.zone])

    const getLocation = async () => {
        if (state.company) {
            setLoadingLocation(true)
            const response = await listApi(logout, `location?idCompany=${state.company}&idRegion=${state.zone}&idSubregion=${state.region}`)
            if (response.status) {
                const temp = response.data.map((doc) => {
                    return {
                        id: doc.id,
                        name: doc.name,
                    }
                })
                setLocation(temp)
                setLoadingLocation(false)
            } else {
                setLocation([])
                setLoadingLocation(false)
            }
        }
    }

    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCompany(temp)
            setLoadingCompany(false)
        } else {
            setCompany([])
            setLoadingCompany(false)
        }
    }

    const getZone = async () => {
        const response = await listApi(logout, 'region?idCompany=' + state.company)
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setZone(temp)
            setLoadingZone(false)
        } else {
            setZone([])
            setLoadingZone(false)
        }
    }

    const getRegion = async () => {
        const response = await listApi(logout, "region/subregion/list?idRegion=" + state.zone)
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setLoadingRegion(false)
            setRegion(temp)
        } else {
            setLoadingRegion(false)
            setRegion([])
        }
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{padding: 10}}>
            <Grid item xs={3}>
                <DatePickerSimple
                    name="dateFrom"
                    label="ORDER DATE (from)"
                    value={state.dateFrom}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={3}>
                <DatePickerSimple
                    name="dateTo"
                    label="ORDER DATE (to)"
                    value={state.dateTo}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={3}>
                <InputText
                    label="Order no"
                    name="id"
                    onChange={handleChange}
                    value={state?.id}
                />
            </Grid>

            <Grid item xs={3}>
                <InputSelect
                    label="Status"
                    name="status"
                    onChange={handleChange}
                    value={state?.status}
                    options={optionsStatus}
                />
            </Grid>

            <Grid item md={3}>
                <InputSelect
                    label="Company"
                    name="company"
                    onChange={handleChange}
                    value={state?.company}
                    options={company}
                    loading={loadingCompany}
                />
            </Grid>

            <Grid item md={3}>
                <InputSelect
                    label="Zone"
                    name="zone"
                    onChange={handleChange}
                    value={state?.zone}
                    options={zone}
                    disabled={!state?.company}
                    loading={loadingZone}
                />
            </Grid>

            <Grid item md={3}>
                <InputSelect
                    label="Region"
                    name="region"
                    onChange={handleChange}
                    value={state?.region}
                    options={region}
                    disabled={!state?.zone}
                    loading={loadingRegion}
                />
            </Grid>

            <Grid item md={3}>
                <InputSelect
                    label="Store"
                    name="location"
                    onChange={handleChange}
                    value={state?.location}
                    options={location}
                    disabled={state.company ? false : true}
                    loading={loadingLocation}
                />
            </Grid>
        </Grid>
    )
}
