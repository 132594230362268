import React, {useEffect, useState} from "react";
import Header from "./Header";
import Table from "./Table";
import {listApi} from "../../../../services/user/request";
import useAuth from "../../../../hooks/useAuth";
import clone from "../../../../utils/clone";

export default function Reports() {

    const { logout } = useAuth()

    const [loadingExport, setLoadingExport] = useState(false)
    const [filter, setFilter] = useState(initialValues())
    const [filterDisabled, setFilterDisabled] = useState(false)

    const clickExport = async () => {
        setLoadingExport(true)

        const response = await listApi(logout, `report/availability`, {
            idCompany: filter?.idCompany?.id,
            idRegion: filter?.idRegion?.id,
            idRegionName: filter?.idRegion?.name,
            idWarehouseName: filter?.idWarehouse?.name,
            idWarehouse: filter?.idWarehouse?.id,
            idProductCategory: filter?.idProductCategory?.id,
            idSemester: filter?.idSemester?.id,
            products: filter?.products,
            weeksRange: filter?.weeksRange?.id,
            export: true
        })

        if (response.status){
            window.open(response.data, '_blank')
        }
        setLoadingExport(false)
    }

    return (
        <>
            <div className="card-body">

                <Header
                    filter={filter}
                    setFilter={setFilter}
                    loadingExport={loadingExport}
                    clickExport={clickExport}
                    filterDisabled={filterDisabled}
                    setFilterDisabled={setFilterDisabled}
                />

                <br/>

                <Table
                    filter={filter}
                    setFilterDisabled={setFilterDisabled}
                />

            </div>
        </>
    )

}

function initialValues() {
    return {
        idRegion: null,
        idCompany: null,
        idWarehouse: null,
        idProductCategory: null,
        idSemester: null,
        weeksRange: null,
    }
}
