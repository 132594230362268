import React, {useEffect, useState} from "react";
import {
    approveTransaction,
    declineTransaction,
    getShipments,
    getTransaction,
    markAsDeliveredApi
} from "../../../../services/user/transaction";
import useAuth from "../../../../hooks/useAuth";
import ProductBox from "./ProductBox";
import {useParams} from "react-router-dom";
import {withStyles} from "@mui/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {toast} from "react-toastify";
import moment from "moment";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: 20,
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 10,
        color: '#a4a4a4',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: 20,
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: 10,
    },
}))(MuiDialogActions);

export default function Details() {
    const {logout, auth} = useAuth();
    const {id} = useParams();

    const [products, setProducts] = useState([]);
    const [details, setDetails] = useState({});
    const [dateOrder, setDateOrder] = useState(null);
    const [reason, setReason] = useState(null);
    const [open, setOpen] = useState(false);
    const [shipments, setShipments] = useState([])

    useEffect(() => {
        (async () => {
            const response = await getTransaction(logout, id);
            if (response.status) {
                setProducts(response.data.products);
                setDetails(response.data.details);

                let date = moment.unix(response.data.details.transaction_creationDate).utc().format('DD-MMM-YYYY HH:mm A')

                setDateOrder(date);

            }

            const shipments = await getShipments(logout, id)
            if (shipments.status) {
                setShipments(shipments.data)
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const approveOrder = async () => {
        const response = await approveTransaction(logout, {id: id});

        if (response.status) {
            window.location.reload();
        } else {
            response.errors.forEach((error) => {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            })
        }
    };

    const handleDialogOpen = () => {
        setOpen(true)
    }

    const handleDialogClose = () => {
        setOpen(false)
    }

    const handleChangeReason = (event) => {
        setReason(event.target.value);
    }

    const markAsDelivered = async () => {
        const response = await markAsDeliveredApi(logout, id);

        if (response.status) {
            window.location.reload()
        }
    }

    const declineOrder = async () => {
        const response = await declineTransaction(logout, {
            id: id,
            reason: reason,
        });

        if (response.status) {
            window.location.reload(true);
            setOpen(false)
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <div className="text-body pb-2">
                        <span className="small">Company:</span>
                        <span className="font-weight-bold small px-2">
                                {details.company_name}
                            </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">User Name:</span>
                        <span className="font-weight-bold small px-2">
                                {details.user_name} {details.user_surnames}
                            </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store:</span>
                        <span className="font-weight-bold small px-2">
                                {details.location_name}
                            </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Address:</span>
                        <span className="font-weight-bold small px-2">
                            <span>
                                {details.location_address1}
                                {details.location_address2 && (
                                    <span>, <br/></span>
                                )}
                            </span>
                            <span>
                                {details.location_address2}
                                {details.location_address3 && (
                                    <span>, <br/></span>
                                )}
                            </span>
                            <span>
                                {details.location_address3}
                                {details.location_address4 && (
                                    <span>, <br/></span>
                                )}
                            </span>
                            <span>
                                {details.location_address4}
                                {details.location_address5 && (
                                    <span>, <br/></span>
                                )}
                            </span>
                            <span>{details.location_address5}, {details.location_postalCode}, {details.listCountry_name}</span>
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store Email:</span>
                        <span className="font-weight-bold small px-2">
                                {details.location_emails}
                            </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store Tel:</span>
                        <span className="font-weight-bold small px-2">
                                {details.location_phone}
                            </span>
                    </div>

                    {/*<div className="text-body pb-2">
                        <a
                            className="btn btn-sm btn-primary transition-3d-hover"
                            href={`/dash/reorder/${details.transaction_id}`}
                        >
                            Reorder
                        </a>
                    </div>*/}
                </Grid>

                <Grid item xs={6}>
                    <div className="text-body pb-2">
                        <span className="small">Order Date:</span>
                        <span className="font-weight-bold small px-2">
                                {dateOrder}
                            </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">No of Packs:</span>
                        <span className="font-weight-bold small px-2">
                                {details.numberOfPacks}
                            </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Order No:</span>
                        <span className="font-weight-bold small px-2">
                                {details.transaction_id}
                            </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Status:</span>
                        <span className="font-weight-bold small px-2">
                                {details.status_name}
                            </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Grand total:</span>
                        <span className="font-weight-bold small px-2">
                                {details?.price}
                            </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Num REF/PO#:</span>
                        <span className="font-weight-bold small px-2">
                                {details.transaction_ref}
                            </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Comment:</span>
                        <span className="font-weight-bold small px-2">
                                {details.transaction_comment}
                            </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Tracking Number:</span>
                        <span className="font-weight-bold small px-2">
                                {shipments.map((shipment, key) => {
                                    return (
                                        <>
                                            {shipment.number},
                                        </>
                                    )
                                })}
                            </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Tracking Comment:</span>
                        <span className="font-weight-bold small px-2">
                                {shipments.map((shipment, key) => {
                                    return (
                                        <>
                                            {shipment.comment},
                                        </>
                                    )
                                })}
                            </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Tracking ETA:</span>
                        <span className="font-weight-bold small px-2">
                                {shipments.map((shipment, key) => {
                                    return (
                                        <>
                                            {shipment.eta && (
                                                shipment.eta
                                            )}
                                        </>
                                    )
                                })}
                            </span>
                    </div>
                    {details.idStatus === "7" && (
                        <div className="text-body pb-2">
                            <span className="small">Reason for declining:</span>
                            <span className="font-weight-bold small px-2">
                                    {details.transaction_declinedReason}
                                </span>
                        </div>
                    )}
                    {details.idStatus === "9" && (
                        <div className="text-body pb-2">
                            <span className="small">Delivered date:</span>
                            <span className="font-weight-bold small px-2">
                                    {moment.unix(details.transaction_deliveredDate).format("DD/MM/YYYY")}
                                </span>
                        </div>
                    )}
                    {details.idStatus === "5" && (
                        <div className="text-body pb-2 text-right">
                            <button
                                className="btn btn-sm btn-primary transition-3d-hover"
                                onClick={markAsDelivered}
                            >
                                Delivered
                            </button>
                        </div>
                    )}
                </Grid>
            </Grid>
            <div className="col-12">
                <div className="row text-center">
                    {["ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER", "ROLE_REGION"].includes(auth.role) &&
                        details.status_name === "Awaiting approval" && (
                            <div className="col-md-4">
                                <button
                                    className="btn btn-sm btn-primary transition-3d-hover"
                                    onClick={approveOrder}
                                >
                                    Approve order
                                </button>
                            </div>
                        )}

                    {["ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER", "ROLE_REGION"].includes(auth.role) &&
                        details.status_name === "Awaiting approval" && (
                            <div className="col-md-4">
                                <a
                                    className="btn btn-sm btn-primary transition-3d-hover"
                                    href={`/dash/order/${details.transaction_id}`}
                                >
                                    Amend or Update order
                                </a>
                            </div>
                        )}

                    {["ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER", "ROLE_REGION"].includes(auth.role) &&
                        details.status_name === "Awaiting approval" && (
                            <div className="col-md-4">
                                <button
                                    className="btn btn-sm btn-primary transition-3d-hover"
                                    onClick={handleDialogOpen}
                                >
                                    Decline order
                                </button>
                                <Dialog onClose={handleDialogClose} aria-labelledby="customized-dialog-title"
                                        open={open}>
                                    <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
                                        Decline Order ({id})
                                    </DialogTitle>
                                    <DialogContent dividers>
                                        <div>Please provide a reason to decline this order.</div>
                                        <br/>
                                        <TextField
                                            name="reason"
                                            label="Reason"
                                            onChange={handleChangeReason}
                                            multiline
                                            minRows={10}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            style={{width: "500px"}}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <div className="col-12 px-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button className="btn btn-sm btn-primary transition-3d-hover"
                                                            onClick={handleDialogClose}>
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <button className="btn btn-sm btn-primary transition-3d-hover"
                                                            onClick={declineOrder}>
                                                        Confirm declining
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </DialogActions>
                                </Dialog>
                            </div>

                        )}
                </div>
            </div>

            <hr/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <span className={"h5"}>Products</span>
                </Grid>
                <Grid item xs={12}>
                    {products.map((doc, key) => (
                        <ProductBox
                            id={doc.id}
                            name={doc.name}
                            description={doc.description}
                            priceDefault={doc.priceDefault}
                            price={doc.priceUnit}
                            category={doc.categoryName}
                            sort={doc.sort}
                            image={doc.image}
                            currencySymbol={doc.currencySymbol}
                            unitsPerPack={doc.unitsPerPack}
                            calculateTotalCart={""}
                            quantityDefault={doc.quantity}
                            unitDefault={doc.unit}
                            calculatePrice={""}
                            quantityIsModify={false}
                            stockOnHand={doc.stockOnHand}
                        />
                    ))}
                </Grid>
                {details.transaction_shippingChargeTotalPrice && (
                        <Grid item xs={12}>
                            <Grid container alignItems={"center"} style={{borderBottom: "1px solid #eef0f5", marginBottom: 20, paddingBottom: 20}}>
                                <Grid item xs={2} className={"text-center"}>
                                    
                                    <LocalShippingIcon
                                    className="avatar avatar-xl"
                                    loading="lazy"
                                    fontSize="large"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <span className={"h5"}>{details.transaction_shippingChargeName}</span>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="text-body">
                                                <span className="small">Category:</span>
                                                <span className="font-weight-bold small px-2">SHIPPING CHARGE</span>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    
                                    {details.transaction_shippingChargeCostPerPack &&  (
                                        <div className="text-body">
                                            <span className="font-weight-bold small pr-1"></span>
                                            <span className="small"></span>
                                            <br/>
                                            
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={3} className={"text-center"}>
                                    <span className="h5"> {details.transaction_shippingChargeCostPerPack} {details.currency}</span>
                                </Grid>
                                <Grid item xs={2} className={"text-center"}>
                                    <span className="h5"> {details.transaction_shippingChargeTotalPrice} {details.currency}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
            </Grid>
        </>
    );
}
