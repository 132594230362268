import React, {useEffect, useMemo, useState} from "react"
import {getToken, hasExpiredToken, removeCart, removeToken, setToken} from "./services/auth/token"
import jwtDecode from "jwt-decode"
import {useHistory, useLocation} from "react-router-dom";
import AuthContext from "./context/AuthContext"

export default function Home(props) {

    const [auth, setAuth] = useState(undefined)
    const [reloadUser, setReloadUser] = useState(false)
    const history = useHistory();
    const location = useLocation();

    // Login

    // Mantener los datos del usuario al recargar
    useEffect(() => {

        const token = getToken()
        if (token) {
            var roleName = ""
            switch (jwtDecode(token).roles[0]) {
                case "ROLE_ADMIN":
                    roleName = "Admin"
                    break;
                case "ROLE_REGION":
                    roleName = "Region"
                    break;
                case "ROLE_STOREG":
                    roleName = "Store"
                    break;
                case "ROLE_COMPANY_MANAGER":
                    roleName = "Company manager"
                    break;
                case "ROLE_REGION_MANAGER":
                    roleName = "Approver/Region"
                    break;
                case "ROLE_MULTI_STORE":
                    roleName = "Multi-Store"
                    break;
                default:
                    roleName = ""
            }

            if (hasExpiredToken(token)) {
                logout()
            }

            setAuth({
                token: token,
                idUser: jwtDecode(token).id,
                role: jwtDecode(token).roles[0],
                email: jwtDecode(token).email,
                roleName: roleName,
                name: jwtDecode(token).name,
                surnames: jwtDecode(token).surnames,
                location: jwtDecode(token).location,
                company_logo: jwtDecode(token).company_logo,
                region: jwtDecode(token).region,
                subregion: jwtDecode(token).subregion,
                country: jwtDecode(token).country,
                companyName: jwtDecode(token).company_name,
                companyId: jwtDecode(token).company_id,
                isAllRegion: jwtDecode(token).isAllRegion,
                isAllSubregion: jwtDecode(token).isAllSubregion,
                isAllStore: jwtDecode(token).isAllStore,
                isAllowedReportProductionPlan: jwtDecode(token).isAllowedReportProductionPlan,
                isAllowedReportSupplyChain: jwtDecode(token).isAllowedReportSupplyChain,
                isAllowedReportAvailability: jwtDecode(token).isAllowedReportAvailability
            })

        } else {
            setAuth(null)
            if (location.pathname.includes("admin") || location.pathname.includes("dash") || location.pathname === "/") {
                history.push("/logout")
            }
        }
        setReloadUser(false)
    }, [reloadUser]) // eslint-disable-next-line react-hooks/exhaustive-deps

    // Guardar los datos del usuarios, useMemo evita que vaya recargando si no se modifica algun dato
    const login = (token) => {
        setToken(token)
        let roleName = ""
        switch (jwtDecode(token).roles[0]) {
            case "ROLE_ADMIN":
                roleName = "Admin";
                break;
            case "ROLE_REGION":
                roleName = "Region";
                break;
            case "ROLE_STOREG":
                roleName = "Store";
                break;
            case "ROLE_COMPANY_MANAGER":
                roleName = "Company manager"
                break;
            case "ROLE_REGION_MANAGER":
                roleName = "Approver/Region"
                break;
            case "ROLE_MULTI_STORE":
                roleName = "Multi-Store"
                break;
            default :
                roleName = ""
        }
        setAuth({
            token: token,
            idUser: jwtDecode(token).id,
            role: jwtDecode(token).roles[0],
            email: jwtDecode(token).email,
            roleName: roleName,
            name: jwtDecode(token).name,
            surnames: jwtDecode(token).surnames,
            location: jwtDecode(token).location,
            company_logo: jwtDecode(token).company_logo,
            region: jwtDecode(token).region,
            subregion: jwtDecode(token).subregion,
            country: jwtDecode(token).country,
            companyName: jwtDecode(token).company_name,
            companyId: jwtDecode(token).company_id,
            isAllRegion: jwtDecode(token).isAllRegion,
            isAllSubregion: jwtDecode(token).isAllSubregion,
            isAllStore: jwtDecode(token).isAllStore,
            isAllowedReportProductionPlan: jwtDecode(token).isAllowedReportProductionPlan,
            isAllowedReportSupplyChain: jwtDecode(token).isAllowedReportSupplyChain,
            isAllowedReportAvailability: jwtDecode(token).isAllowedReportAvailability
        })
    }

    const logout = () => {
        removeToken()
        removeCart()
        setAuth(null)
        history.push("/login")
    }

    const authData = useMemo(
        () => ({
            auth: auth,
            login: login,
            logout: logout,
            setReloadUser: setReloadUser
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [auth]
    )

    if (auth === undefined) {
        return null
    }
    //

    return (
        <AuthContext.Provider value={authData}>
            {props.children}
        </AuthContext.Provider>
    )
}
