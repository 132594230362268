import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useHistory, useParams } from "react-router-dom"
import useAuth from "../../../../hooks/useAuth"
import { createApi, getCurrencyRegionApi, updateApi } from "../../../../services/admin/currencyRegion"
import { listApi } from "../../../../services/admin/request"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import Autocomplete from "@material-ui/lab/Autocomplete"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function Fields() {
    const { id, idProduct } = useParams()
    const { logout } = useAuth()
    const history = useHistory()

    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)
    const [loading, setLoading] = useState(true)
    const [listRegion, setListRegion] = useState([])
    const [listProduct, setListProduct] = useState([])
    const [listCurrency, setListCurrency] = useState([])
    const [defaultRegion, setDefaultRegion] = useState(null)
    const [defaultProduct, setDefaultProduct] = useState(null)
    const [defaultCurrency, setDefaultCurrency] = useState(null)
    const [urlBack, setUrlBack] = useState("/admin/currency-region/management")

    const [initialValues, setInitialValues] = useState({
        id: "",
        idCurrency: null,
        idProduct: null,
        idRegion: null,
        price: "",
        isActive: true
    })

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formData) => {
            setMsgSuccess(false)
            setMsgDanger(null)
            if (id) {
                const response = await updateApi(logout, formData);
                if (response.status) {
                    setMsgSuccess(true)
                } else {
                    setMsgDanger(response.errors)
                }
            } else {

                const response = await createApi(formData)
                if (response.status) {
                    history.push(`/admin/currency-region/edit/${idProduct}/${response.data.currencyProduct.id}`)
                } else {
                    setMsgDanger(response.errors);
                }
            }

        },
    });


    const searchRegions = async (idProduct) => {
        const response = await listApi(logout, `region/company?idProduct=${idProduct}`)
        if (response.status) {
            const listRegion = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListRegion(listRegion)
        }
    };

    useEffect(() => {
        if (idProduct !== 0) {
            setUrlBack(`/admin/currency-region/management`)
        }

        (async () => {
            const response = await listApi(logout, "product")
            if (response.status) {

                let defaultProductSelect = {}
                const listProduct = response.data.map((doc) => {
                    if (idProduct === doc.id) {
                        defaultProductSelect = doc
                    }
                    return {
                        ...doc,
                    };
                });

                setListProduct(listProduct)
                if (idProduct !== 0) {
                    initialValues.idProduct = idProduct
                    setDefaultProduct(defaultProductSelect)
                }

                if (idProduct !== 0) {
                    void searchRegions(idProduct)
                }
            }
        })();

        (async () => {
            const response = await listApi(logout, "currency")
            if (response.status) {
                const listCurrency = response.data.map((doc) => {
                    return {
                        ...doc,
                    }
                })
                setListCurrency(listCurrency)
            }
        })();

        (async () => {
            const response = await getCurrencyRegionApi(logout, { id: id })
            if (response.status) {
                setInitialValues({
                    ...response.data.currencyProduct,
                    price: response.data.currencyProduct.price ?? ""
                })
                setDefaultProduct(response.data.product)
                setDefaultRegion(response.data.region)
                setDefaultCurrency(response.data.currency)
                setLoading(false)
            }
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const changeIdProduct = (event, value) => {
        if (value !== null) {
            formik.values.idProduct = value.id
            setDefaultProduct(value)
            searchRegions(value.id)
        } else {
            setDefaultProduct(null)
            formik.values.idProduct = null
        }
    }

    const changeIdRegion = (event, value) => {
        if (value !== null) {
            formik.values.idRegion = value.id
            setDefaultRegion(value)
        } else {
            setDefaultRegion(null)
            formik.values.idRegion = null
        }
    }

    const changeIdCurrency = (event, value) => {
        if (value !== null) {
            formik.values.idCurrency = value.id
            setDefaultCurrency(value)
        } else {
            setDefaultCurrency(null)
            formik.values.idCurrency = null
        }
    }

    if (loading && !initialValues) return ""

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Button
                            type="button"
                            color="primary"
                            href={urlBack}
                            style={{
                                float: "left"
                            }}
                        >
                            <ArrowBackIos />
                            BACK TO LIST
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <h2 className="p-0">Currency by Zone Settings</h2>
                    </Grid>
                </Grid>

                {msgSuccess && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-success">
                            Currency by Zone data has been saved correctly
                        </Box>
                    </Grid>
                )}

                {msgDanger && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-danger">
                            {msgDanger.map((item) => {
                                return (
                                    <span>
                                        {" "}
                                        {item} <br />{" "}
                                    </span>
                                );
                            })}
                        </Box>
                    </Grid>
                )}

                <Box border={1} className="p-20 box-default">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} className="border-bottom">
                            <h5 className="p-0 mt-0">Info</h5>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {id && (
                                <div className="my-2">
                                    <TextField
                                        label="* ID"
                                        value={formik.values.id}
                                        onChange={formik.handleChange}
                                        name="id"
                                        type="number"
                                        variant="outlined"
                                        className="form-control"
                                        disabled
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            )}
                            <div className="my-2 mb-2">
                                <Autocomplete
                                    name="idProduct"
                                    options={listProduct}
                                    value={defaultProduct}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={changeIdProduct}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Product"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2 mb-2">
                                <Autocomplete
                                    name="idRegion"
                                    options={listRegion}
                                    value={defaultRegion}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={changeIdRegion}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Zone"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Autocomplete
                                        name="idCurrency"
                                        options={listCurrency}
                                        value={defaultCurrency}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) =>
                                            option.value === value.value
                                        }
                                        onChange={changeIdCurrency}
                                        fullWidth
                                        size="small"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="* Currency"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        variant="outlined"
                                        label="Price"
                                        className="form-control"
                                        name="price"
                                        type="number"
                                        value={formik.values.price}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} lg={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formik.values.isActive}
                                                onChange={formik.handleChange}
                                                name="isActive"
                                                color="primary"
                                            />
                                        }
                                        label="Visible"
                                    />
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={3} className="p-20">
                    <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            type="button"
                            color="secondary"
                            href={urlBack}
                            fullWidth
                        >
                            { id ? ( <><ArrowBackIos /> Back to list </>) : "Cancel" }
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            { id ? "Modify" : "Create" }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

function validationSchema() {
    return {
        idCurrency: Yup.number().required("Please provide a valid currency"),
        idProduct: Yup.number().required("Please provide a valid product"),
        idRegion: Yup.number().required("Please provide a valid zone"),
        price: Yup.number()
    };
}
