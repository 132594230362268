import useAuth from "../../../hooks/useAuth";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

export default function MenuLeft(props) {
    const {auth} = useAuth()
    const {menu} = props
    const history = useHistory()

    const name = auth ? auth.name : null;
    const surnames = auth ? auth.surnames : null;
    const roleName = auth ? auth.roleName : null;
    const location = auth ? auth.location : null;
    const isAllowedReportProductionPlan = auth ? auth.isAllowedReportProductionPlan : null;
    const isAllowedReportSupplyChain = auth ? auth.isAllowedReportSupplyChain : null;
    const isAllowedReportAvailability = auth ? auth.isAllowedReportAvailability : null;

    const [classMenu, setClassMenu] = useState({
        orderFrom: "nav-link",
        orderHistory: "nav-link",
        sendReportsProduct: "nav-link",
        sendReportsStore: "nav-link",
        sendReportsStock: "nav-link",
        sendReportsProductionPlan: "nav-link",
        sendReportsSupplyChain: "nav-link",
        sendReportsAvailability: "nav-link",
        sendReportsAvailabilityv2: "nav-link",

    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        switch (menu) {
            case "orderHistory":
                setClassMenu({
                    ...classMenu,
                    orderHistory: "nav-link active",
                });
                break;
            case "sendReportsProduct":
                setClassMenu({
                    ...classMenu,
                    sendReportsProduct: "nav-link active",
                });
                break;
            case "sendReportsStore":
                setClassMenu({
                    ...classMenu,
                    sendReportsStore: "nav-link active",
                });
                break;
            case "sendReportsStock":
                setClassMenu({
                    ...classMenu,
                    sendReportsStock: "nav-link active",
                });
                break;
            case "sendReportsProductionPlan":
                setClassMenu({
                    ...classMenu,
                    sendReportsProductionPlan: "nav-link active",
                })
                break;

            case "sendReportsSupplyChain":
                setClassMenu({
                    ...classMenu,
                    sendReportsSupplyChain: "nav-link active",
                })
                break;
            case "sendReportsAvailability":
                setClassMenu({
                    ...classMenu,
                    sendReportsAvailability: "nav-link active",
                })
                break;
            case "sendReportsAvailabilityv2":
                setClassMenu({
                    ...classMenu,
                    sendReportsAvailabilityv2: "nav-link active",
                })
                break;
            default:
                setClassMenu({
                    ...classMenu,
                    orderFrom: "nav-link active",
                });
                break;
        }
    }, [menu]); // eslint-disable-line react-hooks/exhaustive-deps

    const logout = () => {
        history.push("/logout")
    }

    return (
        <>
            <div className="d-none d-lg-block text-center mb-5">
                <h4 className="card-title">
                    {name} {surnames}
                </h4>
                <p className="card-text font-size-1">
                    {location ? location.name : roleName}
                </p>
            </div>

            <span className="text-cap">Order</span>

            <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-0 mb-lg-4">
                <li className="nav-item">
                    <a className={classMenu.orderFrom} href="/dash/order">
                        Products
                    </a>
                </li>
                <li className="nav-item">
                    <a className={classMenu.orderHistory} href="/dash/order-history">
                        History
                    </a>
                </li>
            </ul>

            <span className="text-cap">Reports</span>

            <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-0 mb-lg-4">
                <li className="nav-item">
                    <a
                        className={classMenu.sendReportsProduct}
                        href="/dash/reports-product"
                    >
                        Products
                    </a>
                </li>

                <li className="nav-item">
                    <a
                        className={classMenu.sendReportsStore}
                        href="/dash/reports-store"
                    >
                        Store
                    </a>
                </li>

                <li className="nav-item">
                    <a
                        className={classMenu.sendReportsStock}
                        href="/dash/reports-stock"
                    >
                        Stock
                    </a>
                </li>
                {isAllowedReportProductionPlan ?
                    <li className="nav-item">
                        <a
                            className={classMenu.sendReportsProductionPlan}
                            href="/dash/reports-productionPlan"
                        >
                            Production Plan
                        </a>
                    </li>

                : ""}

                {isAllowedReportSupplyChain ?
                    <li className="nav-item">
                        <a
                            className={classMenu.sendReportsSupplyChain}
                            href="/dash/reports-supplyChain"
                        >
                            Supply Chain
                        </a>
                    </li>

                : ""}

                {isAllowedReportAvailability ?
                    <>
                    <li className="nav-item">
                        <a
                            className={classMenu.sendReportsAvailability}
                            href="/dash/reports-availability"
                        >
                            Availability V1
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={classMenu.sendReportsAvailabilityv2}
                            href="/dash/reports-availability-v2"
                        >
                            Availability V2
                        </a>
                    </li>
                    </>

                : ""}
            </ul>

            <div>
                <div className="dropdown-divider d-none d-lg-block"></div>

                <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2">
                    <li className="nav-item">
                        <button
                            className="nav-link btn btn-link"
                            onClick={logout}
                        >
                            <i className="fas fa-sign-out-alt nav-icon"></i>
                            Log out
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
}
