import React, {useEffect, useState} from "react";
import {Box, CircularProgress, IconButton, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {DASH_MAX_ROW} from "../../utils/constants";
import {
    GrNext,
    GrPrevious,
} from "react-icons/gr";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import "./table.css";
/**
 *
 *
 * ## PROPS
 * - **columns** *object* Ej: { field: "id", headerName: "ID" }
 * - **rows** *object* Ej: [{id: 1}]]
 * - **loading** *bool*
 * - **rowCount** *integer* Número total de filas
 * - **filter** *object* Ej: { offset: 0, sort: { field: "id", sort: "desc" }}
 * - **setFilter** *object*
 */
export default function TableDetalls(props) {
    const {
        columns,
        rows,
        loading,
        rowCount,
        filter,
        setFilter,
        handleChangeCell
    } = props

    const [rowsIn, setRowsIn] = useState(rows)

    useEffect(() => {
        if (loading) {
            setRowsIn([])
        } else {
            setRowsIn(rows)

        }
    }, [loading, rows])


    const [offsetIn, setOffsetIn] = useState(filter.offset + 1)

    // Pintamos el Offset en caso de tener superar el total de filas
    const [colorOffset, setColorOffset] = useState("black")

    // Nos permite evitar que el offsetIn actualice el offset y por lo tanto, lance una petición
    const [petitionOffset, setPetitionOffset] = useState(true)

    const handleChangeSort = (field, allow) => {
        if (field && allow) {
            let sort = "desc"
            if (filter.sort.field === field) {
                sort = filter.sort.sort === "desc" ? "asc" : "desc"
            }
            setFilter({...filter, sort: {field: field, sort}})
        }
    }

    const handleChangePageNext = () => {
        setFilter({...filter, offset: filter.offset + DASH_MAX_ROW})
        setOffsetIn(filter.offset + DASH_MAX_ROW + 1)
        setPetitionOffset(false)
    }

    const handleChangePagePrevious = () => {
        const offset = (filter.offset - DASH_MAX_ROW) > 0 ? filter.offset - DASH_MAX_ROW : 0
        setFilter({...filter, offset: offset})
        setOffsetIn(offset + 1)
        setPetitionOffset(false)

    }

    const handleChangeOffset = (e) => {
        setOffsetIn(e.target.value)
        if (e.target.value <= rowCount && e.target.value > 0) {
            setColorOffset("black")
        } else {
            setColorOffset("red")
        }
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (offsetIn <= rowCount && offsetIn > 0 && petitionOffset) {
                setFilter({...filter, offset: offsetIn - 1})
            }
            setPetitionOffset(true)
        }, 2000)
        return () => clearTimeout(timeOutId)
    }, [offsetIn]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeInput = (e, key, field) => {
        // Actualizamos el valor de la celda segun la posición del array
        rowsIn[key][field] = e.target.value
        setRowsIn(rowsIn)

        // Enviamos el valor en el array de origen
        handleChangeCell(e)
    }

    // Son las filas que tenemos y las que hemos mostrado en anteriores páginas
    const numRows = rowsIn.length + filter.offset

    return (
        <>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((row, key) => {
                            let sortable = row.sortable ? row.sortable : false
                            return (
                                <>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        key={key}
                                        sx={{background: '#eef0f4', cursor: sortable ? "pointer" : "default"}}
                                        style={{padding: '8px'}}
                                        onClick={() => {
                                            handleChangeSort(row.field, sortable)
                                        }}
                                    >

                                        {filter.sort.field === row.field ?
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                flexWrap: 'nowrap'
                                            }}>
                                                <span className="table-title">{row.headerName}</span>
                                                {(filter.sort.field === row.field && filter.sort.sort === "desc") &&
                                                    <KeyboardArrowDownIcon/>}
                                                {(filter.sort.field === row.field && filter.sort.sort === "asc") &&
                                                    <KeyboardArrowUpIcon/>}
                                            </div>
                                            :
                                            <span className="table-title">{row.headerName}</span>
                                        }


                                    </TableCell>
                                </>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {/*  LOADING */}
                    {loading && (
                        <>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row" size="small" colSpan={columns.length}>
                                    <Box sx={{
                                        maxWidth: "50px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginTop: "50px",
                                        marginBottom: "50px"
                                    }}>
                                        <CircularProgress sx={{ color: '#bababa' }} size={25}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </>
                    )}

                    {/* SIN DATOS */}
                    {(!loading && rowsIn.length === 0) && (

                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row" size="small" colSpan={columns.length}>
                                <Box sx={{
                                    maxWidth: "50px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: "50px",
                                    marginBottom: "50px"
                                }}>
                                    <CustomNoRowsOverlay/>
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}

                    {rowsIn.map((row, key) => {
                        return (
                            <>
                                <TableRow
                                    key={key}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <>
                                        {columns.map((element, keyElement) => {
                                            return (
                                                <>
                                                    {element.renderCell ? (
                                                        <TableCell
                                                            component="td"
                                                            scope="row"
                                                            key={keyElement}
                                                            size="small"
                                                            style={{padding: '8px'}}
                                                        >
                                                            {element.renderCell(row)}
                                                        </TableCell>
                                                    ) : (
                                                        <>
                                                            {element.editable ? (
                                                                <TableCell
                                                                    component="td"
                                                                    scope="row"
                                                                    key={keyElement}
                                                                    size="small"
                                                                    style={{padding: '8px'}}
                                                                >
                                                                    <input
                                                                        id={row.id}
                                                                        style={{
                                                                            textAlign: 'left',
                                                                            fontSize: 14,
                                                                            color: colorOffset,
                                                                            width: '100%',
                                                                            padding: "5px 10px"
                                                                        }}
                                                                        onChange={(e) => handleChangeInput(e, key, element.field)}
                                                                        value={row[element.field]}
                                                                    />
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell
                                                                    component="td"
                                                                    scope="row"
                                                                    key={keyElement}
                                                                    size="small"
                                                                    style={{padding: '8px'}}
                                                                >
                                                                    {row[element.field]}
                                                                </TableCell>
                                                            )}

                                                        </>

                                                    )}
                                                </>
                                            )
                                        })}
                                    </>
                                </TableRow>
                            </>
                        )
                    })}
                </TableBody>
            </Table>
            {
                (!loading && rowCount > 0) && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                        paddingTop={2}
                        style={{borderTop: '1px solid #e0e0e0'}}
                    >
                        {(rowCount > 0) && (
                            <div className="pagination">
                                {(numRows === rowCount && offsetIn === 1) ? (
                                    <div>
                                        <p>
                                            <span style={{fontFamily: "AdminFontMedium"}}>{rowCount}</span> results
                                        </p>
                                    </div>
                                ) : (
                                    <div>Seeing
                                        <input
                                            style={{
                                                textAlign: 'right',
                                                fontSize: '14px',
                                                color: colorOffset,
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                                fontFamily: "AdminFontMedium",
                                                border: "1px solid #eee",
                                                borderRadius: "5px"
                                            }}
                                            onChange={handleChangeOffset}
                                            value={offsetIn}
                                        />
                                        <p>
                                            <span style={{fontFamily: "AdminFontMedium"}}><span
                                                style={{marginRight: 10}}>-</span>
                                                {numRows}</span> to <span
                                            style={{fontFamily: "AdminFontMedium"}}>{rowCount}</span> results
                                        </p>
                                    </div>
                                )}
                                <div className="pagination-buttons">
                                    <button
                                        className="mr-5px"
                                        onClick={handleChangePagePrevious}
                                        disabled={offsetIn === 1}
                                    >
                                        Previous
                                    </button>

                                    <button
                                        onClick={handleChangePageNext}
                                        disabled={numRows === rowCount}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}

                    </Stack>
                )
            }
        </>
    )
}

// Cuando la tabla está bacía
const StyledGridOverlay = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}))

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"/>
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"/>
                    </g>
                </g>
            </svg>
            <Box sx={{mt: 1}}>No Data</Box>
        </StyledGridOverlay>
    );
}
