import {addApi as addTransactionApi} from "../../../services/user/transaction";
import {useHistory} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Grid from "@material-ui/core/Grid";
import {useState} from "react";
import {LoadingButton} from "@material-ui/lab";
import {Button} from "@mui/material";

export default function Footer(props) {
    const {
        totalPrice,
        setViewCart,
        isViewCart,
    } = props;
    const {logout} = useAuth();
    const history = useHistory();
    const [loading, setLoading] = useState(false)

    const makeOrder = async () => {
        setLoading(true)
        let cartProducts = JSON.parse(localStorage.getItem("cart_products"));
        let cartZoneDetails = JSON.parse(localStorage.getItem("cart_zone_details"));
        let cartDetails = JSON.parse(localStorage.getItem("cart_detail"));

        if (cartProducts.length > 0) {
            const responseTransaction = await addTransactionApi(logout, {
                idRegion: cartZoneDetails?.region,
                idLocation: cartZoneDetails?.location,
                cart: cartProducts,
                comment: cartDetails?.comment,
                ref: cartDetails?.ref,
            })

            if (responseTransaction.status) {
                setLoading(false)
                localStorage.removeItem("cart_products");
                localStorage.removeItem("cart_detail");
                localStorage.removeItem("cart_zone_details");
                history.push(
                    `/dash/order-history/details/${responseTransaction.data.transaction}`
                );
            } else {

                responseTransaction.errors.forEach((error) => {
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                })
                setLoading(false)

            }
        }
        setLoading(false)
    };

    return (
        <Grid container spacing={3} justifyContent={"flex-end"}>
            {isViewCart && (
                <Grid item xs={12}>
                    <Grid container spacing={3} justifyContent={"flex-end"}>
                        <Grid item xs={12} md={4}>
                            <Button
                                type="button"
                                variant="contained"
                                className="btn btn-sm btn-primary transition-3d-hover w-100 mt-1"
                                onClick={() => setViewCart(false)}
                            >
                                Revise
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <LoadingButton
                                type="button"
                                loading={loading}
                                loadingPosition="start"
                                variant="contained"
                                fullWidth
                                onClick={makeOrder}
                                className="btn btn-sm btn-primary transition-3d-hover w-100 mt-1"
                                sx={{mt: 1, mb: 2}}
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {!isViewCart && (
                <Grid item xs={12} md={4}>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary transition-3d-hover w-100 mt-1"
                        onClick={() => setViewCart(true)}
                    >
                        Review
                    </button>
                </Grid>
            )}
            <Grid item xs={4} md={2} className={"text-right"}>
                {Object.keys(totalPrice).map((currency, i) => {
                    let price = totalPrice[currency];
                    return (
                        <span key={i} className="h6 d-block mb-1">
                            {price} {currency}
                        </span>
                    );
                })}
            </Grid>
        </Grid>
    );
}
