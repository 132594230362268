/**
 * Esta vista también la usamos dentro de producto, y realizamos unos cambios mediante el id de producto seleccionado.
 * En el caso que no tenemos id de producto mostramos todos los elementos sin filtrar
 */

import React, {useEffect, useMemo, useState} from "react";
import {listApi} from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import TableBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

export default function Product(props) {
    const {id} = props
    const {logout} = useAuth()
    const [data, setData] = useState(null)


    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "company"
            },
            {
                Header: "ZONE",
                accessor: "region",
                Filter: SelectColumnFilter
            },
            {
                Header: "PRODUCT",
                accessor: "product",
                Filter: SelectColumnFilter
            },
            {
                Header: "CURRENCY",
                accessor: "currency",
                Filter: SelectColumnFilter
            },
            {
                Header: "PRICE",
                accessor: "price",
                disableFilters: true
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    );


    useEffect(() => {
        (async () => {
            let params = ""
            if (id !== 0) {
                params = `?idProduct=${id}`
            }
            const response = await listApi(logout, "currency-region", params)
            if (response.status) {
                const temp = response.data.map((doc) => {
                    let href = `/admin/currency-region/edit/${doc.idProduct}/${doc.id}`
                    let price = doc.price ?? "default"
                    return {
                        ...doc,
                        id: doc.id,
                        price: price,
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                    }
                })
                setData(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!data) return ""

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        {(id !== 0) ? (<TableBase
                                columns={columns}
                                data={data}
                                selectedRows={data}
                                onSelectedRowsChange={setData}/>) :
                            <TableBase
                                columns={columns}
                                data={data}
                                selectedRows={data}
                                onSelectedRowsChange={setData}/>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
