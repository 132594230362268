import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase from "../../../../components/Admin/TableFilterBaser";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@mui/styles";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginRight: 10,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: 20,
    },
}));
// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const classes = useStyles();

    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
        >
            <Select
                value={filterValue}
                onChange={(e) => {
                    setFilter(e.target.value);
                }}
                sx={{
                    "& label.Mui-focused": {
                        display: "none"
                    },
                    "& legend": {
                        display: "none"
                    }
                }}
                InputLabelProps={{shrink: true}}
            >
                <MenuItem value="">
                    <em>All</em>
                </MenuItem>
                {options.map((option, i) => (
                    <MenuItem key={i} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default function Product() {
    const { id } = useParams();
    const { logout } = useAuth();

    const [products, setProducts] = useState(null);

    const columns = useMemo(
        () => [
            {
                Header: "CAN BUY",
                accessor: "canBy",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "CODE",
                accessor: "code",
                disableFilters: true
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
                disableFilters: true
            },
            {
                Header: "PRICE",
                accessor: "price",
                disableFilters: true
            },
            {
                Header: "CURRENCY",
                accessor: "currency_symbol",
                disableFilters: true
            },
            {
                Header: "WAREHOUSE",
                accessor: "warehouseName",
                disableFilters: true
            },
            {
                Header: "TARIFF",
                accessor: "tariff",
                disableFilters: true
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    );

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, `location/products/${id}`);
            if (response.status) {
                const temp = response.data.map((doc) => {
                    let hrefProduct = `/admin/product/edit/${doc.id}/1`;
                    let warehouse = (
                        <span className="ml-2">Edit Warehouse</span>
                    );
                    if (doc.idWarehouse) {
                        warehouse = (
                            <Link
                                color="primary"
                                href={`/admin/warehouse/edit/${doc.idWarehouse}/1`}
                                className="ml-2"
                                target="_blank"
                                disabled
                            >
                                Edit Warehouse
                            </Link>
                        );
                    }

                    return {
                        ...doc,
                        id: doc.id,
                        code: doc.code,
                        isActive: doc.isActive === "1" ? "Yes" : "No",
                        canBy: doc.canBy ? "Yes" : "No",
                        isAssigned: doc.isAssigned ? "Yes" : "No",
                        tariff: doc.tariff ? "Yes" : "No",
                        actions: (
                            <>
                                <Link
                                    color="primary"
                                    href={hrefProduct}
                                    target="_blank"
                                >
                                    Edit Product
                                </Link>

                                {warehouse}
                            </>
                        ),
                    };
                });
                setProducts(temp);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!products) return "";

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableFilterBase
                            columns={columns}
                            data={products}
                            selectedRows={products}
                            onSelectedRowsChange={setProducts}
                            search={false}
                            selectable={false}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
