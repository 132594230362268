import { BASE_PATH, PATH_BASE_EXTENSION } from "../../utils/constants"
import { authFetch } from "../../utils/fetch"
import axios from "axios";

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${ localStorage.getItem("token")}`}};
    let query = "";

    if (props.dateFrom) { query += `&dateFrom=${props.dateFrom}`; }
    if (props.dateTo) { query += `&dateTo=${props.dateTo}`; }
    if (props.status) { query += `&status=${props.status}`; }
    if (props.company) { query += `&company=${props.company}`; }
    if (props.region) { query += `&region=${props.region}`; }
    if (props.subregion) { query += `&subregion=${props.subregion}`; }
    if (props.location) { query += `&location=${props.location}`; }
    if (props.id) { query += `&id=${props.id}`; }
    if (props.exportExcel) { query += `&exportExcel=${props.exportExcel}`; }

    return axios.get(`${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/list?limit=${props.limit}&offset=${props.offset}${query}`, config)
}

export async function addApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/add`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getTransaction(logout, id) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/${id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function updateTransaction(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/update`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export async function createEditShipment(logout, route, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/${route}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function deleteShipment(logout, route, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/shipment/delete/${route}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function exportOrders(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/export`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function resendEmailsApi(logout, id) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/resend/${id}`
        const token = localStorage.getItem("token")
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getTransactionLog(logout, props, id) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/log/${id}?limit=${props.limit}&offset=${props.offset}&sort=${props.sort}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getAllTransactionLog(logout, props) {
    try {

        let query = "";

        if (props.user) {
            query += `&user=${props.user}`
        }
        if (props.userId) {
            query += `&userId=${props.userId}`
        }
        if (props.transactionId) {
            query += `&transactionId=${props.transactionId}`
        }
        if (props.dateToTime) {
            query += `&dateToTime=${props.dateToTime}`
        }
        if (props.dateToDate) {
            query += `&dateToDate=${props.dateToDate}`
        }
        if (props.dateFromTime) {
            query += `&dateFromTime=${props.dateFromTime}`
        }
        if (props.dateFromDate) {
            query += `&dateFromDate=${props.dateFromDate}`
        }
        if (props.country) {
            query += `&country=${props.country}`
        }
        if (props.region) {
            query += `&region=${props.region}`
        }
        if (props.zone) {
            query += `&zone=${props.zone}`
        }
        if (props.company) {
            query += `&company=${props.company}`
        }
        if (props.location) {
            query += `&location=${props.location}`
        }

        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/logs?limit=${props.limit}&offset=${props.offset}&sort=${props.sort}&export=${props.exportRow}${query}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getAllTransactionKPILog(logout, props) {
    try {

        let query = "";
        if (props.transactionId) {
            query += `&transactionId=${props.transactionId}`
        }
        if (props.dateCreatedFrom) {
            query += `&dateCreatedFrom=${props.dateCreatedFrom}`
        }
        if (props.dateCreatedTo) {
            query += `&dateCreatedTo=${props.dateCreatedTo}`
        }
        if (props.company) {
            query += `&company=${props.company}`
        }
        if (props.location) {
            query += `&location=${props.location}`
        }
        if (props.country) {
            query += `&country=${props.country}`
        }
        if (props.region) {
            query += `&region=${props.region}`
        }
        if (props.zone) {
            query += `&zone=${props.zone}`
        }

        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/kpi-logs?limit=${props.limit}&offset=${props.offset}&sort=${props.sort}&export=${props.exportRow}${query}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}
