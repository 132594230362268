import React, {useEffect, useMemo, useState} from "react";
import {showUsersApi} from "../../../../services/admin/user";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import moment from "moment";

export default function Management() {
    const {logout} = useAuth();
    const [users, setUsers] = useState(null);

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "company",
                Filter: SelectColumnFilter,
            },
            {
                Header: "ZONE",
                accessor: "region",
                Filter: SelectColumnFilter,
            },
            {
                Header: "REGION",
                accessor: "subregion",
                Filter: SelectColumnFilter,
            },
            {
                Header: "COUNTRY",
                accessor: "country",
                Filter: SelectColumnFilter,
            },
            {
                Header: "STORE",
                accessor: "location",
                disableFilters: true,
            },
            {
                Header: "NAME",
                accessor: "name",
                disableFilters: true,
            },
            {
                Header: "EMAIL",
                accessor: "email",
            },
            {
                Header: "USER ROLE",
                accessor: "role",
                Filter: SelectColumnFilter,
            },
            {
                Header: "ACCESS",
                accessor: "access",
                Filter: SelectColumnFilter
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true,
            },
        ],
        []
    );

    useEffect(() => {
        (async () => {
            const response = await showUsersApi(logout);
            if (response.status) {

                const temp = response.data.map((doc) => {
                    let href = `/admin/users/edit/${doc.id}`;

                    let role = "";
                    switch (doc.idUserRole.key) {
                        case "ROLE_ADMIN":
                            role = "Admin";
                            break;
                        case "ROLE_REGION":
                            role = "Region";
                            break;
                        case "ROLE_MULTI_STORE":
                            role = "MultiStore";
                            break;
                        case "ROLE_STOREG":
                            role = "Store";
                            break;
                        case "ROLE_COMPANY_MANAGER":
                            role = "Company Manager";
                            break;
                        case "ROLE_REGION_MANAGER":
                            role = "Approver/Region";
                            break;
                        default:
                            role = "No Role Available";
                            break;
                    }

                    let access = "No";
                    if (doc.isActive) {
                        access = "Yes";
                    }

                    return {
                        id: doc.id,
                        role: role,
                        access: access,
                        active: doc.lastActivity ? moment.unix(doc.lastActivity).format("D/M/Y") : "",
                        company: doc.idCompany?.name,
                        country: doc.idCountry?.name,
                        region: doc.idRegion?.name,
                        subregion: doc.idSubregion?.name,
                        location: doc.locations?.toString(),
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                        ...doc,
                    };
                });
                setUsers(temp);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!users) return "";

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Maintain Users</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{float: "right", margin: "20px 0px"}}
                        href="/admin/users/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableFilterBase
                            columns={columns}
                            data={users}
                            selectedRows={users}
                            onSelectedRowsChange={setUsers}
                            search={false}
                            selectable={false}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
