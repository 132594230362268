import React, {useEffect, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import {Grid, TextField} from "@material-ui/core";
import imgNoData from "../../../../assets/userTheme/assets/svg/components/empty-state-no-data.svg";
import ProductBox from "../ProductBox";
import {listApi as listProductsApi} from "../../../../services/user/product";
import HeaderOrder from "../HeaderOrder";
import {makeStyles} from "@mui/styles";
import Footer from "../Footer";
import Loading from "../../../../components/User/Loading";
import InputText from "../../../../components/Inputs/InputText";

const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "white",
        marginBottom: 20,
    },

});

export default function Store(props) {

    const classes = useStyles();

    const {
        setTotalProducts,
        setTotalCartProducts,
        isViewCart,
        setViewCart,
    } = props

    const {logout} = useAuth();
    const [noData, setNoData] = useState(true)
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [filterRegion, setFilterRegion] = useState(true);
    const [filterSubregion, setFilterSubregion] = useState(true);
    const [filterCountry, setFilterCountry] = useState(true);
    const [filterLocation, setFilterLocation] = useState(true);
    const [totalPrice, setTotalPrice] = useState([]);

    const [country, setCountry] = useState([]);
    const [countrySelected, setCountrySelected] = useState(null);
    const [region, setRegion] = useState([]);
    const [regionSelected, setRegionSelected] = useState(null);
    const [subregion, setSubregion] = useState([])
    const [subregionSelected, setSubregionSelected] = useState(null);
    const [productCategory, setProductCategory] = useState([]);
    const [productCategorySelected, setProductCategorySelected] = useState(null);
    const [locationSelected, setLocationSelected] = useState(null);
    const [location, setLocation] = useState([]);
    const [submitForm, setSubmitForm] = useState({
        comment: "",
        ref: "",
    });

    useEffect(() => {
        (async () => {
            const cartProducts = localStorage.getItem("cart_products")
            const cartDetail = localStorage.getItem("cart_detail")
            if (!cartProducts) {
                localStorage.setItem('cart_products', "[]")
            }
            if (!cartDetail) {
                localStorage.setItem('cart_detail', JSON.stringify(submitForm))
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            const cart = JSON.parse(localStorage.getItem("cart_products"));

            const temp = products.map((prod) => {

                const quantity = cart?.find(prodCart => prod.id === prodCart.id)?.quantity || ""

                return {
                    ...prod,
                    quantity: quantity
                }
            })

            await setProducts([])
            setProducts(temp)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isViewCart])

    const handleChange = (event) => {
        submitForm[event.target.name] = event.target.value;
        setSubmitForm({...submitForm});
        localStorage.setItem("cart_detail", JSON.stringify(submitForm))
    };

    const listProducts = async (idLocation, productCategory, productCatalog) => {

        const cart_version_app = localStorage.getItem('cart_version_app')

        if (!cart_version_app) {
            localStorage.setItem('cart_version_app', '1')
            localStorage.setItem('cart_products', JSON.stringify([]))
        }


        const cart = JSON.parse(localStorage.getItem("cart_products"));
        if (localStorage.getItem("cart_detail")) {
            setSubmitForm(JSON.parse(localStorage.getItem("cart_detail")));
        }

        setNoData(true)
        setProducts([]);
        setLoading(true)
        const response = await listProductsApi(logout, {
            idLocation: idLocation,
            productCategory: productCategory,
            productCatalog: productCatalog
        });

        if (response.status) {
            setTotalProducts(response.data.length)
            const temp = response.data?.map((prod) => {
                const quantity = cart?.find(prodCart => prod.id === prodCart.id)?.quantity || ""
                return {
                    ...prod,
                    quantity: quantity
                }
            })

            setProducts(temp)
            setNoData(false)
            setLoading(false)
            calculatePrice();
        } else {
            setLoading(false)
            setNoData(true)
        }
    };
    const calculatePrice = () => {
        const cart = JSON.parse(localStorage.getItem("cart_products"));

        let tempPrice = [];
        products.forEach((item) => {
            cart?.forEach((storage) => {
                if (storage.id === item.id) {
                    if (!tempPrice[item.currency_symbol] && item.priceDefault) {
                        tempPrice[item.currency_symbol] = null;
                    }
                    tempPrice[item.currency_symbol] =
                        tempPrice[item.currency_symbol] +
                        item.priceDefault * storage.quantity;
                }
            });
        });

        setTotalPrice(tempPrice);
    };

    const number_format = (user_input) => {
        for (const property in user_input) {
            user_input[property] = parseFloat(user_input[property]).toFixed(2);
        }
        return user_input;
    }

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <InputText
                            label="Num REF/PO#"
                            name="ref"
                            value={submitForm?.ref}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputText
                            label="Comment"
                            name="comment"
                            value={submitForm?.comment}
                            onChange={handleChange}
                            rows={2}
                            multiline={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <HeaderOrder
                                listProducts={listProducts}
                                setFilterRegion={setFilterRegion}
                                setFilterCountry={setFilterCountry}
                                setFilterLocation={setFilterLocation}
                                setFilterSubregion={setFilterSubregion}
                                filterRegion={filterRegion}
                                filterCountry={filterCountry}
                                filterLocation={filterLocation}
                                filterSubregion={filterSubregion}
                                regionSelected={regionSelected}
                                subregionSelected={subregionSelected}
                                countrySelected={countrySelected}
                                productCategorySelected={productCategorySelected}
                                setCountrySelected={setCountrySelected}
                                setRegionSelected={setRegionSelected}
                                setSubregionSelected={setSubregionSelected}
                                setProductCategorySelected={setProductCategorySelected}
                                setLocationSelected={setLocationSelected}
                                locationSelected={locationSelected}
                                region={region}
                                setRegion={setRegion}
                                country={country}
                                setCountry={setCountry}
                                subregion={subregion}
                                setSubregion={setSubregion}
                                location={location}
                                setLocation={setLocation}
                                productCategory={productCategory}
                                setProductCategory={setProductCategory}
                                noData={noData}
                                setNoData={setNoData}
                                setProducts={setProducts}
                                loading={loading}
                            />

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {!noData && (
                            <Grid container style={{backgroundColor: "#f1f2f6", borderRadius: 10, padding: 10}}>
                                <Grid item xs={2} className={"text-center"}>
                                    <span className={"h5"}>Image</span>
                                </Grid>
                                <Grid item xs={3} className={"text-center"}>
                                    <span className={"h5"}>Item Description</span>
                                </Grid>
                                <Grid item xs={2} className={"text-center"}>
                                    <span className={"h5"}>Quantity</span>
                                </Grid>
                                <Grid item xs={3} className={"text-center"}>
                                    <span className={"h5"}>Pack price</span>
                                </Grid>
                                <Grid item xs={2} className={"text-center"}>
                                    <span className={"h5"}>Total Price</span>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {(noData && !loading) && (
                        <Grid container spacing={1} className="text-center">
                            <Grid item xs={12}>
                                <img src={imgNoData} className="avatar avatar-xl mb-3" alt="Product"/>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="card-text">There's no data available</p>
                            </Grid>
                        </Grid>
                    )}

                    <Loading show={loading} title="Fetching products..."/>


                    {products?.map((doc, key) => {
                        return (
                            <ProductBox
                                key={doc.id}
                                id={doc.id}
                                name={doc.name}
                                description={doc.description}
                                price={doc.price}
                                sort={doc.sort}
                                priceDefault={doc.priceDefault}
                                category={doc.category_name}
                                image={doc.image}
                                currencySymbol={doc.currency_symbol}
                                unitsPerPack={doc.unitsPerPack}
                                setTotalCartProducts={setTotalCartProducts}
                                quantityDefault={doc.quantity}
                                unitDefault={doc.unit}
                                calculatePrice={calculatePrice}
                                quantityIsModify={true}
                                stockOnHand={doc.stockOnHand}
                                idWarehouse={doc.idWarehouse}
                            />
                        )
                    })}
                </Grid>
                {products.length > 0 && (
                    <Grid item xs={12}>
                        <Footer
                            totalPrice={number_format(totalPrice)}
                            submitForm={submitForm}
                            setViewCart={setViewCart}
                            isViewCart={isViewCart}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    )
}

