import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../../../services/admin/request";
import {
    updateTransaction,
    getTransaction,
    resendEmailsApi
} from "../../../../services/admin/transaction";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import TableBase from "../../../../components/Admin/TableBase";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import InputRadio from "../../../../components/Admin/Inputs/InputRadio";
import {Alert} from "@mui/lab";

export default function Edit() {
    const { id } = useParams();
    const { logout } = useAuth();
    const [msgSuccess, setMsgSuccess] = useState(null);
    const [msgDanger, setMsgDanger] = useState(null);

    const [loading, setLoading] = useState(true);
    const [productos, setProducts] = useState([]);
    const [details, setDetails] = useState({});
    const [dataOrder, setDataOrder] = useState(null);
    const [numberPacks, setNumberPacks] = useState(0);
    const [listStatus, setListStatus] = useState([]);
    const [defaultStatus, setDefaultStatus] = useState({});
    const [defaultStatusOrigin, setDefaultStatusOrigin] = useState({});

    const columns = useMemo(
        () => [
            {
                Header: "REFERENCE",
                accessor: "sort",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
            },
            {
                Header: "QUANTITY",
                accessor: "quantity",
            },
            {
                Header: "TOTAL",
                accessor: "totalPrice",
            },
            {
                Header: "CURRENCY",
                accessor: "currencyName",
            },
            {
                Header: "WAREHOUSE",
                accessor: "warehouseName",
            },
            {
                Header: "TARIFF",
                accessor: "tariffId",
            }
        ],
        []
    );

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        (async () => {
            const allStatus = await listApi(logout, "transaction/status");

            if (allStatus.status) {
                setListStatus(allStatus.data);
            }

            const response = await getTransaction(logout, id);
            if (response.status) {
                // get Details
                setDetails(response.data.details);
                setDataOrder(moment.unix(response.data.details.transaction_creationDate).utc().format('DD-MMM-YYYY HH:mm A'));
                setDefaultStatus(response.data.status);
                setDefaultStatusOrigin(response.data.status);

                if (response.data.details.transaction_shippingChargeTotalPrice) {
                    response.data.products.push({
                        sort:'',
                        name: response.data.details.transaction_shippingChargeName,
                        description:'Shipping charge for products',
                        quantity:0,
                        totalPrice: response.data.details.transaction_shippingChargeTotalPrice,
                        currencyName:'',
                        warehouseName:'',
                        tariffId:''
                    });
                }

                // get Products
                let numberPacks = 0;
                let currencyName = '';
                const products = response.data.products.map((doc) => {
                    numberPacks += parseInt(doc.quantity);

                    if(doc.currencyName){
                        currencyName = doc.currencyName;
                    }
                    if(!doc.currencyName){
                        doc.currencyName = currencyName;
                    }
                    if(!doc.quantity){
                        doc.quantity = "";
                    }

                    return {
                        ...doc,
                        tariffId : doc.tariffId ? <a href={`/admin/tariff/edit/${doc.tariffId}`} target="_blank">{doc.tariffName}</a> : "",
                        warehouseName : doc.warehouseId ? <a href={`/admin/warehouse/edit/${doc.warehouseId}/4`} target="_blank">{doc.warehouseName}</a> : "",
                    };
                });
                setProducts(products);
                setNumberPacks(numberPacks);

                setLoading(false);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const resendEmails = async () => {
        const response = await resendEmailsApi(logout, id)

        if (response.status) {
            setMsgSuccess(response.data)
        } else {
            setMsgDanger(response.errors)
        }

    }

    const updateDetails = async () => {
        setMsgSuccess(null);
        setMsgDanger(null);

        const response = await updateTransaction(logout, details);
        if (response.status) {
            setMsgSuccess("The status has been saved successfully");
            setDefaultStatusOrigin({id: details?.idStatus})
        } else {
            setMsgDanger(response.errors);
        }
    };

    const handleChangeStatus = (event) => {
        details["idStatus"] = parseInt(event.target.value)
        setDetails({ ...details })
    }

    useEffect(() => {
        console.clear()
        console.log(details)
    }, [details])

    if (loading) return "";

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <h2 className="p-0">Display Orders Settings</h2>
                </Grid>
            </Grid>

            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">
                        {msgSuccess}
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>
                                    {" "}
                                    {item} <br />{" "}
                                </span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={8}>
                    <Box border={1} className="p-20 box-default">
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                className="border-bottom">
                                <h5 className="p-0 mt-0">Order Info</h5>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                style={{textAlign: "right", padding: 13}}
                                className="border-bottom"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={resendEmails}
                                >
                                    Resend
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Customer Name
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.user_name}{" "}
                                                    {details.user_surnames}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Email Address
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.user_email}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Order Number
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.transaction_id}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Creation Date
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {dataOrder}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                QTY
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {numberPacks}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                TOTAL
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.price}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>

                            {details.transaction_isImport && (
                                <Grid item xs={12} md={12} lg={12}>
                                    <p>This order has been generated using the Import System.</p>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Box border={1} className="p-20 box-default">
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="border-bottom"
                            >
                                <h5 className="p-0 mt-0">Order Status</h5>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{paddingTop: 10}}>
                                <div className="my-2 mb-2">

                                     <InputRadio
                                         name="status"
                                         value={1}
                                         onChange={handleChangeStatus}
                                         checked={details?.idStatus === 1 ? true : false}
                                         label="Created"
                                         disabled={true}
                                     />

                                    <InputRadio
                                        name="status"
                                        value={2}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 2 ? true : false}
                                        label="Awaiting approval"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5
                                        }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={3}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 3 ? true : false}
                                        label="Approved"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5
                                        }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={8}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 8 ? true : false}
                                        label="On hold"
                                        disabled={
                                            defaultStatusOrigin?.id === 1 ||
                                            defaultStatusOrigin?.id === 4 ||
                                            defaultStatusOrigin?.id === 5 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 7
                                    }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={4}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 4 ? true : false}
                                        label="Processing"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5
                                        }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={5}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 5 ? true : false}
                                        label="Shipped"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5 ||
                                            defaultStatusOrigin?.id === 8
                                        }
                                    />

                                    <hr/>

                                    <InputRadio
                                        name="status"
                                        value={6}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 6 ? true : false}
                                        label="Canceled"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5
                                        }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={7}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 7 ? true : false}
                                        label="Declined"
                                        disabled={true}
                                    />
                                </div>

                                {((defaultStatusOrigin?.id === 2 || defaultStatusOrigin?.id === 3 || defaultStatusOrigin?.id === 4 || defaultStatusOrigin?.id === 8) && (details?.idStatus === 6 || details?.idStatus === 7)) && (
                                    <>
                                        <Alert severity="warning">The stock will be returned to the warehouse.</Alert><br/>
                                    </>
                                )}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="small"
                                    onClick={updateDetails}
                                    fullWidth
                                    disabled={defaultStatusOrigin?.id === 7}
                                >
                                    Modify
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="p-20 box-default">
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="border-bottom"
                            >
                                <h5 className="p-0 mt-0">Store</h5>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Company
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.company_name}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Name
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.location_name}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                NetSuite Code
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.location_code}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Comment
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {
                                                        details.transaction_comment
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Region
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.region_name}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Phone Number
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.location_phone}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Address
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    <span>
                                                        {
                                                            details.location_address1
                                                        }
                                                    </span>
                                                    {details.location_address2 && (
                                                        <br />
                                                    )}
                                                    {details.location_address2 && (
                                                        <span>
                                                            {
                                                                details.location_address2
                                                            }
                                                        </span>
                                                    )}
                                                    {details.location_address3 && (
                                                        <br />
                                                    )}
                                                    {details.location_address3 && (
                                                        <span>
                                                            {
                                                                details.location_address3
                                                            }
                                                        </span>
                                                    )}
                                                    {details.location_address4 && (
                                                        <br />
                                                    )}
                                                    {details.location_address4 && (
                                                        <span>
                                                            {
                                                                details.location_address4
                                                            }
                                                        </span>
                                                    )}
                                                    {details.location_postalCode && (
                                                        <br />
                                                    )}
                                                    {details.location_postalCode && (
                                                        <span>
                                                            {
                                                                details.location_postalCode
                                                            }
                                                        </span>
                                                    )}
                                                    {details.location_address5 && (
                                                        <br />
                                                    )}
                                                    {details.location_address5 && (
                                                        <span>
                                                            {
                                                                details.location_address5
                                                            }
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Num REF/PO#
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.transaction_ref}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase
                            columns={columns}
                            data={productos}
                            selectedRows={productos}
                            onSelectedRowsChange={setProducts}
                            search={false}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
