import React, {useEffect, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import BookIcon from '@material-ui/icons/Book';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import EmailIcon from '@mui/icons-material/Email';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PublishIcon from '@mui/icons-material/Publish';

export default function menuTwo(props) {
    const {menu} = props

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [classMenu, setClassMenu] = useState({
        transaction: false,
        warehouse: false,
        shippings: false,
        transactionLog:false
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        switch (menu) {
            case "transaction":
                setClassMenu({
                    ...classMenu,
                    transaction: true
                })
                break
            case "transaction-log":
                setClassMenu({
                    ...classMenu,
                    transactionLog: true
                })
                break
            case "warehouse":
                setClassMenu({
                    ...classMenu,
                    warehouse: true
                })
                break

            case "shippings":
                setClassMenu({
                    ...classMenu,
                    shippings: true
                })
                break
            case "containers":
                setClassMenu({
                    ...classMenu,
                    containers: true
                })
                break
            case "logMail":
                setClassMenu({
                    ...classMenu,
                    logMail: true
                })
                break
            case "doc":
                setClassMenu({
                    ...classMenu,
                    doc: true
                })
                break
            case "import":
                setClassMenu({
                    ...classMenu,
                    import: true
                })
                break
            default:
                break;
        }
    }, [menu]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <ListItem
                button
                component="a"
                href="/admin/transaction/management"
                selected={classMenu.transaction}
            >
                <ListItemIcon>
                    <LocalMallIcon/>
                </ListItemIcon>
                <ListItemText primary="Display Orders"/>
            </ListItem>
            <ListItem
                button
                component="a"
                href="/admin/transactionLog/management"
                selected={classMenu.transactionLog}
            >
                <ListItemIcon>
                    <BookIcon/>
                </ListItemIcon>
                <ListItemText primary="Orders Log/KPI"/>
            </ListItem>
            <ListItem
                button
                component="a"
                href="/admin/shippings/management"
                selected={classMenu.shippings}
            >
                <ListItemIcon>
                    <LocalShippingIcon/>
                </ListItemIcon>
                <ListItemText primary="Display Shipments"/>
            </ListItem>
            <ListItem
                button
                component="a"
                href="/admin/containers/management"
                selected={classMenu.containers}
            >
                <ListItemIcon>
                    <DirectionsBoatIcon/>
                </ListItemIcon>
                <ListItemText primary="Display Containers"/>
            </ListItem>
            <Divider/>
            <ListItem
                button
                component="a"
                href="/admin/warehouse/management"
                selected={classMenu.warehouse}
            >
                <ListItemIcon>
                    <HomeWorkIcon/>
                </ListItemIcon>
                <ListItemText primary="Warehouse"/>
            </ListItem>
            <Divider/>
            <ListItem
                button
                component="a"
                href="/admin/log/mail"
                selected={classMenu.logMail}
            >
                <ListItemIcon>
                    <EmailIcon/>
                </ListItemIcon>
                <ListItemText primary="Log Mail"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/doc/install"
                selected={classMenu.doc}
            >
                <ListItemIcon>
                    <LibraryBooksIcon/>
                </ListItemIcon>
                <ListItemText primary="Bookstores"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/import"
                selected={classMenu.import}
            >
                <ListItemIcon>
                    <PublishIcon/>
                </ListItemIcon>
                <ListItemText primary="Import"/>
            </ListItem>
        </div>
    );
}
