import React, { useEffect, useState, useMemo } from "react";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export default function Fields() {

    const [company, setCompany] = useState(null)
    const { logout } = useAuth()

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "companyName",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
            },
            {
                Header: "CATALOG",
                accessor: "catalogName",
            },
            {
                Header: "PRODUCT CATEGORY",
                accessor: "categoryName",
            },
            {
                Header: "PRODUCT TYPE",
                accessor: "productTypeName",
            },
            {
                Header: "ACTIVE",
                accessor: "active",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "product");
            if (response.status) {
                const temp = response.data.map((doc) => {


                    let href = `/admin/product/edit/${doc.id}/1`;

                    let active = "No";
                    if (doc.isActive) {
                        active = "Yes";
                    }

                    return {
                        id: doc.id,
                        active: active,
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                        ...doc,
                    };
                });
                setCompany(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!company) return ""

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Maintain Products</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", margin: "20px 0px" }}
                        href="/admin/product/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableFilterBase
                            columns={columns}
                            data={company}
                            selectedRows={company}
                            onSelectedRowsChange={setCompany}
                            search={false}
                            selectable={false}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
