import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createApi, getWarehouseApi, updateApi } from "../../../../services/admin/warehouse";
import { useHistory, useParams } from "react-router-dom";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function Fields() {
    const { id } = useParams()
    const { logout } = useAuth()
    const history = useHistory()

    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)
    const [initialValues, setInitialValues] = useState(defaultValues())

    const [listRegion, setListRegion] = useState([])
    const [defaultRegion, setDefaultRegion] = useState({})

    const [imageBase64, setImageBase64] = useState(null)
    const [image, setImage] = useState(null)

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async () => {
            setMsgDanger(null)

            let f = new FormData()
            for (const property in formik.values) {
                if (property !== "image"){
                    f.append(`${property}`, formik.values[property])
                }
            }
            if (image) {
                f.append("image", image[0])
            }

            if (id) {
                const response = await updateApi(logout, f)
                if (response.status) {
                    setMsgSuccess(true)
                } else {
                    setMsgDanger(response.errors)
                }
            } else {

                const response = await createApi(f);
                if (response.status) {
                    history.push(`/admin/warehouse/edit/${response.data.warehouse.id}/1`)
                } else {
                    setMsgDanger(response.errors)
                }

            }
        },
    });

    // Company
    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "region");
            if (response.status) {
                const temp = response.data.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc,
                    };
                });
                setListRegion(temp)
            }
        })();

        (async () => {
            if (id) {
                const response = await getWarehouseApi(logout, { id: id })
                if (response.status) {
                    setImageBase64(response.data.warehouse.image)
                    setInitialValues(response.data.warehouse)
                    setDefaultRegion(response.data.idRegion)
                }
            }
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!initialValues) return ""

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {msgSuccess && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-success">
                            Warehouse data has been saved correctly
                        </Box>
                    </Grid>
                )}

                {msgDanger && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-danger">
                            {msgDanger.map((item) => {
                                return (
                                    <span>
                                        {" "}
                                        {item} <br />{" "}
                                    </span>
                                );
                            })}
                        </Box>
                    </Grid>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12}  md={12} lg={12} className="border-bottom">
                                    <h4 className="p-0 mt-0">Info</h4>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    { imageBase64 && (
                                        <div className="my-2 mb-2">
                                            <img
                                                alt="Warehouse"
                                                src={imageBase64}
                                                style={{
                                                    maxWidth: "200px",
                                                    width: "200px"
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Image"
                                            className="form-control"
                                            name="file"
                                            type="file"
                                            onChange={(event) => {
                                                let reader = new FileReader()
                                                setImage(event.target.files)

                                                let file = event.currentTarget.files[0]
                                                reader.onloadend = () => {
                                                    setImageBase64(reader.result)
                                                }
                                                reader.readAsDataURL(file)
                                            }}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>

                                    { id && (
                                        <div className="my-2">
                                            <TextField
                                                variant="outlined"
                                                label="* ID Warehouse"
                                                className="form-control"
                                                name="id"
                                                type="number"
                                                value={formik.values.id}
                                                fullWidth
                                                size="small"
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    )}

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* NetSuite ID"
                                            className="form-control"
                                            name="code"
                                            type="text"
                                            value={formik.values?.code}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.code && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.code}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Name"
                                            className="form-control"
                                            name="name"
                                            type="text"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.name && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.name}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Telephone"
                                            className="form-control"
                                            name="phone"
                                            type="text"
                                            value={formik.values?.phone}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                        />
                                        {formik.errors.phone && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.phone}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Delivery lead time"
                                            className="form-control"
                                            name="deliveryLeadTime"
                                            type="text"
                                            value={
                                                formik.values?.deliveryLeadTime
                                            }
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.deliveryLeadTime && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.deliveryLeadTime}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Emails"
                                            name="email"
                                            onChange={formik.handleChange}
                                            value={formik.values?.email}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.email && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.email}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    checked={
                                                        formik.values?.isActive
                                                    }
                                                    name="isActive"
                                                    color="primary"
                                                />
                                            }
                                            label="Visible"
                                        />
                                    </div>

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                    <h4 className="p-0 mt-0">Address</h4>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Post Code"
                                            name="postalCode"
                                            className="form-control"
                                            value={formik.values?.postalCode}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.postalCode && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.postalCode}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2 mb-2">
                                         <Autocomplete
                                            options={listRegion}
                                            value={defaultRegion}
                                            getOptionLabel={(option) => option.name || ""}
                                            getOptionSelected={(option, value) => option.value === value.value }
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    formik.values.idRegion = newValue.id
                                                    setDefaultRegion(newValue)
                                                } else {
                                                    formik.values.idRegion = null
                                                    setDefaultRegion({})
                                                }
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="* Region"
                                                    variant="outlined"
                                                    name="idRegion"
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Address 1"
                                            className="form-control"
                                            name="address1"
                                            value={formik.values?.address1}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.address1 && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.address1}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="Address 2"
                                            className="form-control"
                                            value={formik.values?.address2}
                                            name="address2"
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            className="form-control"
                                            label="Address3"
                                            value={formik.values?.address3}
                                            name="address3"
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            className="form-control"
                                            label="Address4"
                                            value={formik.values?.address4}
                                            name="address4"
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            className="form-control"
                                            label="Address5"
                                            name="address5"
                                            value={formik.values?.address5}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid container spacing={3} className="p-20">
                        <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                        <Grid item xs={6} md={3} lg={3}>
                            <Button
                                type="button"
                                color="secondary"
                                href="/admin/warehouse/management"
                                fullWidth
                            >
                                <ArrowBackIos />
                                Back to list
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={3} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                            >
                                Modify
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

function defaultValues() {
    return {
        id: "",
        code: "",
        name: "",
        phone: "",
        deliveryLeadTime: "",
        email: "",
        idRegion: null,
        postalCode: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
        isActive: true
    }
}

function validationSchema() {
    return {
        code: Yup.string().required("The code is required"),
        name: Yup.string().required("The name is required"),
        phone: Yup.string().required("The Telephone is required"),
        deliveryLeadTime: Yup.string().required("The Delivery lead time"),
        email: Yup.string().required("The emails is required"),
        postalCode: Yup.string().required("The Postal Code is required"),
        address1: Yup.string().required("The Address 1 is required")
    }
}
